import { localStorageUsuarioName } from '../../data/config/config';

export function AtualizaCampos(e, setDadosMsg, setBotaoLigado) {
    setDadosMsg({ msg: e.target.value });
    if (e.target.value.trim()) {
        setBotaoLigado(true);
    } else {
        setBotaoLigado(false);
    }
}

// Atualiza o objeto usuário do armazenamento local
export function AtualizaLocalStorageUsuario (usuario) {
    localStorage.setItem(localStorageUsuarioName, JSON.stringify(usuario));
}

export function RetornaDadosUsuario () {
    const usuario = localStorage.getItem(localStorageUsuarioName);
    if (usuario) {
        return JSON.parse(usuario);
    }
    return null;
}