import React, { useContext, useState, useEffect, useRef } from 'react';
import { parse, isAfter, isBefore } from 'date-fns';

import { AlertTitle, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Collapse, IconButton, Box, Paper, Alert} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PaymentIcon from '@mui/icons-material/Payment';

import ApiCall from '../../services/api';
import { VariaveisContext } from '../../services/variaveisProvider';
import { renderStatusHistorico, renderStatusChip } from './funcoes';
import Loading from '../gerais/Loading/Loading';
import CancelamentoSemPagamento from '../Payment/CancelamentoSemPagamento';
import CancelamentoComPagamento from '../Payment/CancelamentoComPagamento';
import ModalEnviarMsg from '../ModalEnviarMsg/ModalEnviarMsg';

function ScheduleManagement(props) {
  const { loading , setLoading, usuario } = useContext(VariaveisContext);
  
  
  const aulasAtivas = useRef ([]);
  const aulasInativas = useRef ([]);
  const numAulasAtivas = useRef (0);
  const numAulasInativas = useRef (0);

   
  const [openCancelamentoSemPagamento, setOpenCancelamentoSemPagamento] = useState(false);
  const [openCancelamentoComPagamento, setOpenCancelamentoComPagamento] = useState(false);
  const [openMensagem, setOpenMensagem] = useState(false);
  const numAula = useRef (0);
  
  function MontaAulas (resposta) {
    
    let a = resposta;
    
    if (a.length === 1 && a[0].id === 0) {
    if (a[0].id === 0) {
      numAulasAtivas.current = 0;
      numAulasInativas.current = 0;
      
    }} else {
      
      let aulasAtivasTemp = a.filter((item) => {
        const data1 = parse(item.data, 'dd/MM/yyyy', new Date(), { locale: 'pt-BR' });
        const data2 = parse(new Date ().toLocaleDateString ('pt-BR'), 'dd/MM/yyyy', new Date(), { locale: 'pt-BR' });
        let depois = isAfter (data1, data2);
        let igual = data1 === data2;
        if ((depois || igual) && (item.status === 'agendada' || item.status === 'pré-agendada')) return item;
      }
      );

      let b = [...aulasAtivasTemp];
            
      const arrayNovo = [];
      
      b.forEach((objeto) => {
        const { id, inicio, fim, assuntos, comentarios, data, idhorario1, idhorario2, idpedido, link, modelo, status, statuspagamento, valor, desconto } = objeto;
      
        const indice = arrayNovo.findIndex((objeto) => objeto.id === id);
      
        if (indice === -1) {
          arrayNovo.push({ id, horarios: [{ inicio, fim }], assuntos, comentarios, data, idhorario1, idhorario2, idpedido, link, modelo, status, statuspagamento, valor });
        } else {
          arrayNovo[indice].horarios.push({ inicio, fim });
         
        }
      });
      
      aulasAtivas.current = [...arrayNovo];      
      
      let aulasInativasTemp = a.filter((item) => {
        const data1 = parse(item.data, 'dd/MM/yyyy', new Date(), { locale: 'pt-BR' });
        const data2 = parse(new Date ().toLocaleDateString ('pt-BR'), 'dd/MM/yyyy', new Date(), { locale: 'pt-BR' });
        let antes = isBefore (data1, data2);
        if ((antes) && (item.status === 'agendada' || item.status === 'pré-agendada')) return item;
      }
      ); 
      
      let c = [...aulasInativasTemp];
            
      const arrayNovo2 = [];
      
      c.forEach((objeto) => {
        const { id, inicio, fim, assuntos, comentarios, data, idhorario1, idhorario2, idpedido, link, modelo, status, statuspagamento, valor, desconto } = objeto;
      
        const indice = arrayNovo2.findIndex((objeto) => objeto.id === id);
      
        if (indice === -1) {
          arrayNovo2.push({ id, horarios: [{ inicio, fim }], assuntos, comentarios, data, idhorario1, idhorario2, idpedido, link, modelo, status, statuspagamento, valor });
        } else {
          arrayNovo2[indice].horarios.push({ inicio, fim });
          let valorTotal = arrayNovo2[indice].valor + valor;
          arrayNovo2[indice].valor = (1 - (desconto / 100)) * valorTotal ;
        }
      });
      
      aulasInativas.current = [...arrayNovo2];      

      if (aulasAtivas.current === undefined || aulasAtivas.current === null) {
        numAulasAtivas.current = 0;
      } else {
        numAulasAtivas.current = aulasAtivas.current.length; 
      }

      if (aulasInativas.current === undefined || aulasInativas.current === null) {
        numAulasInativas.current = 0;
      } else {
        numAulasInativas.current = aulasInativas.current.length; 
      }
}
    }
  
 
  // Função para chamar a API de login ou cadastro de nova conta 
  async function callApi(url, data) {
     
    const result = await ApiCall(url, data, loading); // chama a API de login

    if (result.newLoading.isSuccess) {
            
      MontaAulas (result.newLoading.response.data);
      setLoading(result.newLoading); // Atualiza o estado de loading
    } else setLoading(result.newLoading); // Atualiza o estado de loading
  }

  useEffect(() => {
    setLoading ({ ...loading, inicio: true });
    let data = { idUsuario: usuario.current.id };
    
    callApi("listarAulas", data); // chama a API de login

  }, []);
 
  function handleOpenPagamentoSemCancelamento (e, aula) {
    e.preventDefault();
    numAula.current = aula.id;
    setOpenCancelamentoSemPagamento (true);
  }

  function handleOpenPagamentoComCancelamento (e, aula, index) {
    e.preventDefault();
    numAula.current = index;
    setOpenCancelamentoComPagamento (true);
    
  }

  function handleOpenMensagem (e, aula) {
    e.preventDefault();
    numAula.current = aula.id;
    setOpenMensagem (true);
  }
  
 function LinhaAulaAtiva (props) {
  
  const { aula, index } = props;
  const [open, setOpen] = React.useState(false);
  
  return (
    <React.Fragment>        
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
     
          <TableCell>
<IconButton
aria-label="expand row"
size="small"
onClick={() => setOpen(!open)}
>
{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
</IconButton>
</TableCell>

              <TableCell>{index + 1}</TableCell>
              <TableCell>{aula.data}</TableCell>
              
              <TableCell>{renderStatusChip(aula.status)}</TableCell>
              <TableCell>{renderStatusChip(aula.statuspagamento)}</TableCell>
              <TableCell>
                
              {aula.statuspagamento === 'pago' ?
                <IconButton
                  color="primary"
                  onClick={(e) => {handleOpenMensagem (e, aula) 
                    // Implementar a lógica para modificar o dia da aula
                  } }
                >
                  <EditIcon />
                </IconButton> : null}
                
                {aula.statuspagamento === 'pagamento não aceito' || aula.statuspagamento === 'aguardando pagamento' ?
                <IconButton
                  color="primary"
                  onClick={() => {
                    // Implementar a lógica para modificar o dia da aula
                  } }
                >
                  <PaymentIcon />
                </IconButton> : null}
                
                {aula.statuspagamento !== 'cancelado' && aula.statuspagamento === 'pago' ?
                <IconButton
                  color="error"
                  onClick={(e) => {handleOpenPagamentoComCancelamento (e, aula, index) }
                }
                  
                >
                  <CancelIcon />
                </IconButton> : null}
              {/*
                {aula.statuspagamento !== 'cancelado' && aula.statuspagamento !== 'pago' ?
                <IconButton
                  color="error"
                  onClick={(e) => {handleOpenPagamentoSemCancelamento (e, aula) }
                }
                  
                >
                  <CancelIcon />
                </IconButton> : null}
              
              */}
                </TableCell>
               
              </TableRow>  
                 
            
            <TableRow >
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
<Collapse in={open} timeout="auto" unmountOnExit>
<Box sx={{ margin: 1 }}>

                    <Typography variant = {'body2'}>
                      <strong>Link da aula:</strong>{' '}
                      <a href={aula.link} target="_blank" rel="noopener noreferrer">
                        {aula.link !== null ? aula.link : ' indisponível'}
                      </a>
                    </Typography> 
                    <p></p>
                    <TableRow>
                      <strong>{aula.horarios.length === 1 ? 'Horário: ' : 'Horários: '}</strong>
                      
                      {aula.horarios[0].inicio + ' - ' + aula.horarios[0].fim}
                      {aula.horarios.length === 2 ? ' e ' + aula.horarios[1].inicio + ' - ' + aula.horarios[1].fim  : null}
                      
                                        
                    </TableRow>
                    <Typography variant = {'body2'}>
                      <strong>Assuntos:</strong> {aula.assuntos}
                    </Typography>
                    <Typography variant = {'body2'}>
                      <strong>Modelo da aula:</strong> {aula.modelo !=='' ? 'Teoria e exercícios' : 'Teoria'}
                    </Typography>
                    <Typography variant = {'body2'}>
                      <strong>Adicionais:</strong> {aula.comentarios}
                    </Typography>
                    <p></p>
                    <Typography variant = {'body2'}>
                      <strong>Valor da aula:</strong> {'R$ ' + aula.valor + ',00'}
                    </Typography>
                    <Typography variant = {'body2'}>
                      <strong>ID do pedido: </strong>{aula.idpedido !== null ? aula.idpedido : ' indisponível'} 
                    </Typography>
                  </Box>
               </Collapse> 
              </TableCell>
            </TableRow>
    </React.Fragment>
    )
} 

function LinhaAulaInativa (props) {
  
  const { aula, index } = props;
  const [open, setOpen] = React.useState(false);
  
  return (
    <React.Fragment>        
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
     
          <TableCell>
<IconButton
aria-label="expand row"
size="small"
onClick={() => setOpen(!open)}
>
{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
</IconButton>
</TableCell>

              <TableCell>{index + 1}</TableCell>
              <TableCell>{aula.data}</TableCell>
              <TableCell>{renderStatusHistorico (aula.status, aula.statuspagamento)}</TableCell>
              
              </TableRow>  
                 
            
            <TableRow >
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
<Collapse in={open} timeout="auto" unmountOnExit>
<Box sx={{ margin: 1 }}>

                    
                    <TableRow>
                      <strong>{aula.horarios.length === 1 ? 'Horário: ' : 'Horários: '}</strong>
                      
                      {aula.horarios[0].inicio + ' - ' + aula.horarios[0].fim}
                      {aula.horarios.length === 2 ? ' e ' + aula.horarios[1].inicio + ' - ' + aula.horarios[1].fim  : null}
                      
                                        
                    </TableRow>
                    <Typography variant = {'body2'}>
                      <strong>Assuntos:</strong> {aula.assuntos}
                    </Typography>
                    <Typography variant = {'body2'}>
                      <strong>Modelo da aula:</strong> {aula.modelo !=='' ? 'Teoria e exercícios' : 'Teoria'}
                    </Typography>
                    <Typography variant = {'body2'}>
                      <strong>Adicionais:</strong> {aula.comentarios}
                    </Typography>
                    <p></p>
                    <Typography variant = {'body2'}>
                      <strong>Valor da aula:</strong> {'R$ ' + aula.valor + ',00'}
                    </Typography>
                    <Typography variant = {'body2'}>
                      <strong>ID do pedido: </strong>{aula.idpedido !== null ? aula.idpedido : ' indisponível'} 
                    </Typography>
                  </Box>
               </Collapse> 
              </TableCell>
            </TableRow>
    </React.Fragment>
    )
} 


  return (
    <div className="schedule-management">
      <Typography sx={{ marginLeft: '5px', marginBottom: '15px' }} variant="h5" component="div">
        Gerenciamento das aulas
      </Typography>
      
      {loading.fim && !loading.isSuccess ? <>
        <Alert sx={{marginTop: "3em"}} severity="error">
               <AlertTitle>Erro</AlertTitle>
          {loading.modalMsg}
          </Alert> 
     
      </> : null}

      {loading.inicio ? <Loading open={loading.inicio} msg={"Carregando aulas"}/> : 
          
        
          loading.fim && loading.isSuccess ? <> 
          <Card variant="outlined">
            
              <CardContent>
                <h5 className="mb-1">Aulas agendadas</h5>
                <hr></hr>

                {/* Aulas agendadas */}
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                  <Table size="small" aria-label="a dense table" sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableHead>
                      <TableRow >
                      <TableCell />

                        <TableCell>Aula</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Pagamento</TableCell>
                       <TableCell>Ações</TableCell> 
                      </TableRow>
                    </TableHead>
                    
                    
                    
                    
                      {numAulasAtivas.current === 0 ?
                        <Typography sx={{ marginLeft: '1em', marginBottom: '1em', marginTop: '1em' }} component="h6">
                          Você não possui aulas agendadas no momento
                        </Typography> :
                   
                   
                    <TableBody>                                
                    
                    {aulasAtivas.current.map((aula, index) => (
                      
                      <LinhaAulaAtiva aula={aula} index={index} key={aula.id} />
                    ))}
                        
                    </TableBody>
                  }              
                  </Table>
                </TableContainer>
                <div className="mt-5">
                  <h5 className="mb-1">
                    Histórico das aulas
                  </h5>
                  <hr></hr>
                </div>
                
                {/* HISTORICO DAS AULAS */}
                
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell> </TableCell>
                        <TableCell>Aula</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    
                      {numAulasInativas.current === 0 ?
                        <Typography sx={{ marginLeft: '1em', marginBottom: '1em', marginTop: '1em' }} component="h6">
                          Você não possui histórico de aulas agendadas
                        </Typography> :
                    <TableBody>                                
                    
                    {aulasInativas.current.map((aula, index) => (
                      
                      <LinhaAulaInativa aula={aula} index={index} key={aula.id} />
                    ))}
                        
                    </TableBody>
                  } 
                  </Table>
                </TableContainer>
              </CardContent>
            
          </Card> </> : null}
    
      {openCancelamentoSemPagamento ? <CancelamentoSemPagamento aulaId = {numAula.current} setOpenCancelamento={setOpenCancelamentoSemPagamento} /> : null}
      {openCancelamentoComPagamento ? <CancelamentoComPagamento dados = {{id: aulasAtivas.current[numAula.current].id, idPedido: aulasAtivas.current[numAula.current].idpedido, 
                                      idUsuario: usuario.current.id, idHorarios: [aulasAtivas.current[numAula.current].idhorario1, aulasAtivas.current[numAula.current].idhorario2]}} setOpenCancelamento={setOpenCancelamentoComPagamento} /> : null}
      {openMensagem ? <ModalEnviarMsg dados = {{idAula: numAula.current, idUsuario: Number (usuario.current.id), data: new Date ().toLocaleDateString('pt-BR')}} setOpenMensagem={setOpenMensagem} /> : null}
    
    </div>

  );
}

export default ScheduleManagement;
