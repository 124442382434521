import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, Link, Typography, Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LanguageIcon from '@mui/icons-material/Language';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';

export default function Footer() {
    const theme = useTheme((theme) => ({
        footer: {
          marginTop: theme.spacing(8),
          padding: theme.spacing(6, 0),
          backgroundColor: theme.palette.grey[800],
        },
      }));
    const classes = theme;

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg" sx={{ backgroundColor: "#303030"}}>
      <Typography variant="h6" align="center" gutterBottom color='#ffffff'>
        Contatos
      </Typography>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start', mt: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
        <EmailIcon sx={{ mr: 1, color:'#ffffff' }}/>
        <Link href="mailto:brunofernandes@gmail.com">
        <Typography variant="subtitle1" color='#ffffff' component="p" >
          Email 
        </Typography>
        </Link>
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
        <PlayCircleFilledWhiteOutlinedIcon sx={{ mr: 1, color:'#ffffff' }} />
        <Link href="https://www.youtube.com/channel/UCAJTZJ_DNREkUHoWLOptrkg">
        <Typography variant="subtitle1" color='#ffffff' component="p">
          Youtube
        </Typography>
        </Link>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
        <InstagramIcon sx={{ mr: 1, color:'#ffffff' }} />
        <Link href="https://www.instagram.com/brunocastilhosfernandes/">
        <Typography variant="subtitle1" color='#ffffff' component="p">
          Instagram 
        </Typography>
        </Link>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1, mb: 2 }}>
        <HelpOutlineIcon sx={{ mr: 1, color:'#ffffff' }} />
        <Link href="/faq">
        <Typography variant="subtitle1" color='#ffffff' component="p">
          FAQ 
        </Typography>
        </Link>
      </Box>

      

      </Box>
    </Container>
    </footer>
  );
}