import { useState, useContext } from 'react';
import ModalSucessoErro from '../gerais/ModalSucessoErro/ModalSucessoErro';
import { VariaveisContext } from '../../services/variaveisProvider'; 
import Loading from '../gerais/Loading/Loading';
import ApiCall from '../../services/api';

import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';

function ModalEnviarMsg (props) {

const { dados } = props;    
const { loading, setLoading } = useContext (VariaveisContext);    
const [estado, setEstado] = useState(0); // em qual parte do cancelamento está o usuário (0 - tela de confirmacao, 1 - Loading, 2 - msg final)
const [open, setOpen] = useState(true);
const [msg, setMsg] = useState('');

async function EnviarMsgServidor () {
    
    setEstado (1); 
    const res = await ApiCall ('enviarMsg', {idUsuario: dados.idUsuario, msg: msg, data: dados.data, idAula: dados.idAula}, loading);
    setLoading (res.newLoading);
    setEstado (2);

   
}

function handleFechamento () {
    
    setEstado (-1);
    setOpen (false);
    props.setOpenMensagem(false);
}
return <>
    {estado === 0 ? <>
        
        <Dialog open={open} onClose={() => handleFechamento()}>
        <DialogTitle>Mensagem</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Envie uma mensagem para o professor em caso de algum problema com relação à aula marcada.
          </DialogContentText>
          <Box sx={{marginTop: '2em'}}>
          <TextField
            onChange={(e) => setMsg (e.target.value)}
            fullWidth
            id="mensagem"
            label="Mensagem"
            variant="outlined"
            multiline
            rows={4} />
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {handleFechamento ()}}>Cancelar</Button>
          {msg !== '' ? <Button onClick={() => {EnviarMsgServidor()}}>Enviar</Button> : <Button disabled>Enviar</Button>}
          
        </DialogActions>
      </Dialog>

    </> : null}
    
    {estado === 1 ? <Loading open={open} msg='Enviando a mensagem...'></Loading> : null}
    {estado === 2 && loading.isSuccess ? <ModalSucessoErro open={true} tipo='success' msg='Mensagem enviada com sucesso!' btn1='OK' onClickBtn1={() => window.location.reload()} /> : null}
    {estado === 2 && !loading.isSuccess ? <ModalSucessoErro open={true} tipo='error' msg='Mensagem não cancelada - não foi possível a conexão com os nossos servidores. Tente novamente mais tarde.' 
                                          btn1='Fechar' onClickBtn1={() => handleFechamento()} /> : null}
</>
} export default ModalEnviarMsg;