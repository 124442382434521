import './LoginStyles.css';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiCall from '../../services/api';

import Login from '../../components/Login/Login';
import CriarConta from '../../components/Login/CriarConta';

import { Box, Container } from '@mui/material';

import SnackbarAviso from '../../components/gerais/SnackBar/SnackBarAviso';

import { VariaveisContext } from '../../services/variaveisProvider';

import { CriaLocalStorageUsuario, AlteraEstado, AtualizaLocalStorageUsuario } from './funcoes'


const LoginPage = () => {
  const [estado, setEstado] = useState(0);
  const [dadosLogin, setDadosLogin] = useState({ email: '', senha: '' });
  const [dadosCriarConta, setDadosCriarConta] = useState({ nome: '', sobrenome: '', email: '', senha: '', repetirSenha: '' });
  const { usuario, loading, setLoading } = useContext(VariaveisContext);

  const navigate = useNavigate ();
  
      
  // Função para chamar a API de login ou cadastro de nova conta 
  async function callApi(url, data) {
    const result = await ApiCall(url, data, loading); // chama a API de login
    
    setLoading (result.newLoading); // Atualiza o estado de loading
        
    if (result.newLoading.isSuccess) {
      // SALVAR DADOS DO USUÁRIO NO LOCAL STORAGE
      // Criar local storage para usuário caso não exista
      CriaLocalStorageUsuario(result.newLoading.response.data);
      AtualizaLocalStorageUsuario (result.newLoading.response.data); // Atualiza local storage com os dados do usuário (caso já exista
      usuario.current = {...result.newLoading.response.data};
            
      navigate ('/'); // Redireciona para a página inicial (tela de selecionar os horários)
    }
  }
  
 const handleLogin = (event) => {
    event.preventDefault();
    setLoading({...loading, inicio: true}); // inicia estado de loading
   
    callApi ("login", {email: dadosLogin.email, senha: dadosLogin.senha}); // chama a API de login
  };

  const handleCriarConta = (event) => {
    event.preventDefault();
    setLoading({...loading, inicio: true}); // inicia estado de loading
    
    callApi ("cadastrar", {nome: dadosCriarConta.nome, sobrenome: dadosCriarConta.sobrenome, email: dadosCriarConta.email, senha: dadosCriarConta.senha}); // chamada a API de cadastro de nova conta
  };

  return (
    <>
    <div className="body-background overlay" >
       
      <Box sx={{ maxWidth: 500, mx: 'auto', px: 5,  marginTop: 8, marginBottom: 5, display: 'flex',  flexDirection: 'column', alignItems: 'center', 
                 borderRadius: '4px', border: 'solid', borderWidth: '1px', borderColor: 'rgba(0,0,0,0.12)', paddingY: '1em',
                 backgroundColor: 'white'
      }} variant="outlined">
      
          {estado === 0 && (
            <Login handleLogin={handleLogin} estado={estado} alterarParaCriarConta={(e) => AlteraEstado (e, 1, setEstado, setDadosLogin, setDadosCriarConta)} dadosLogin={dadosLogin} setDadosLogin={setDadosLogin} isLoading={loading.inicio} />
          )}
          {estado === 1 && (
            <CriarConta handleCriarConta={handleCriarConta} estado={estado} alterarParaLogin={(e) => AlteraEstado (e, 0, setEstado, setDadosLogin, setDadosCriarConta)} dadosCriarConta={dadosCriarConta} setDadosCriarConta={setDadosCriarConta} isLoading={loading.inicio} />
          )}
     </Box>
     
     {/* Alerta de erro ou sucesso */} 
     <SnackbarAviso open={loading.fim} tipo={loading.modalTipo} msg={loading.modalMsg} onClose={() => setLoading({...loading, fim: false})}></SnackbarAviso>
    
    </div>
    </>
  );
};

export default LoginPage;
