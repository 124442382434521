import { useState } from 'react';
import { Card, CardContent, TextField, Button, Box } from '@mui/material';

import { RetornaDadosUsuario, AtualizaCampos } from './funcoes';

function FormularioContato({ enviarMensagem }) {

  const [dadosMsg, setDadosMsg] = useState({ msg: '' });
  const [botaoLigado, setBotaoLigado] = useState(false);

  function PrepararDadosServidor (e) {
    e.preventDefault();
    let a = RetornaDadosUsuario();
    let b = dadosMsg;
    let c = { ...a, ...b };
        
    enviarMensagem(e, c);
  }

  return (
    <Card variant="outlined" sx={{ mt: 4 }}>
     
        <CardContent>
          <h5 className="mb-1">Mensagem</h5>
          <hr />
          <TextField
            
            fullWidth
            id="mensagem"
            label="Mensagem para o professor"
            variant="outlined"
            value={dadosMsg.mensagem}
            onChange={(e) => AtualizaCampos (e, setDadosMsg, setBotaoLigado)}
            multiline
            rows={4} />
          <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              disabled={!botaoLigado}
              onClick={(e) => PrepararDadosServidor(e)}
            >
              Enviar
            </Button>
          </Box>
        </CardContent>
     
    </Card>
  );
}

export default FormularioContato;