// página para testar coisas novas

import { useState } from "react";
import Loading2 from "../components/gerais/Loading2/Loading2";
import { Button } from "@mui/material";

function Teste () {
  
    const [loading, setLoading] = useState({ inicio : true, response: '', error: '', isSuccess: false});
    
    function handleLoadingFinish (response) {
        setLoading (response);
        console.log ('response: ', response);
    }
  
    function handleButtonClick (e) {
        e.preventDefault();
        setLoading ({...loading, inicio: true, fim: false});
       
        
    }

  return <>
  <div>
       
         {loading.inicio ? <Loading2 
            open={loading.inicio} 
            tipo={'oculto'}
            msg={'Testandoooo'} 
            onClose={(response) => handleLoadingFinish (response)}
            modal = {{mostrar: true, tipo: 'modal', successTipo: 'success', errorTipo: 'error', btn1: {mostrar: true, label: 'OK', color: 'primary'}, btn2: {mostrar: false}}}
            api={{type: 'post', endpoint:'login', data: {email: "profbrunocfernandes@gmail.com", senha: '1'}}}
            ></Loading2> : null}
        
        <Button onClick={(e) => handleButtonClick(e)}>Teste</Button>
        <h4>{loading.inicio ? "iniciou: true" : "iniciou: false"}</h4>
        <h4>{loading.isSuccess ? loading.response.msg : null}</h4>
        

  </div>
  </>
 } export default Teste;