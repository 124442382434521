import { createContext, useEffect, useState, useRef } from 'react';
import { localStorageUsuarioName } from '../data/config/config';
const VariaveisContext = createContext();

function VariaveisProvider(props) {
    
    const [loading, setLoading] = useState ({inicio: false, fim: false, isSuccess: false, msg: '', response: null, error: null,  modalTipoGeral: 'snack', modalMsg: 'Erro desconhecido', modalVariant: "filled", modalTipo: "success", modalIsAlert: true, modalTimeout: 3000});
    const [horarios, setHorarios] = useState ([]);
    const [horariosDoDia, setHorariosDoDia] = useState ([]);
    const [horariosSelecionados, setHorariosSelecionados] = useState ([]);
    const [mudouNaoPago, setMudouNaoPago] = useState (false); 
    const horarioClicado = useRef ([0, 0]);
    const selectedAssuntos = useRef ({assuntos: [], modelo: "teoria", comentarios: ""});
    const [ {selectedDate, primeiroDiaDisponivel, isSuccess}, setData] = useState ({selectedDate: new Date(), primeiroDiaDisponivel: new Date(), isSuccess: false});
    const usuario = useRef (RetornaUsuarioLocalStorage ());
    const liberadoParaPagamento = useRef (false); // indica se passou no teste de horario disponivel no servidor. Se sim, libera para fazer o pagamento no PagSeguro
    

    // Verificar se tem algum botão clicado. Se sim, colocar na variável horarioClicado. Se não, zerar esta variável
    useEffect(() => {
      if (horariosDoDia !== 'undefined' || horariosDoDia !== null) {
      let temp = [];
      horariosDoDia.forEach ((horario) => {
        if (horario.selecionado === 's') {
          temp.push (horario.id);
        }
      });
      horarioClicado.current = temp;
      
     }}, [horariosDoDia]);
    
    

    function RetornaUsuarioLocalStorage () {
      let usuario = {}
      usuario = JSON.parse (localStorage.getItem (localStorageUsuarioName));
      return usuario;
    }
    
    return <VariaveisContext.Provider value={{loading, setLoading, horarios, setHorarios, 
           horariosDoDia, setHorariosDoDia, horariosSelecionados, setHorariosSelecionados,
           selectedDate, primeiroDiaDisponivel, isSuccess, setData, horarioClicado, mudouNaoPago, setMudouNaoPago,
           selectedAssuntos, usuario, liberadoParaPagamento 
     }}>
                {props.children}
           </VariaveisContext.Provider> 
}

export { VariaveisContext, VariaveisProvider};
