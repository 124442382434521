import React from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';



function NavBar() {
  
    const theme = useTheme((theme) => ({
        appBar: {
          backgroundColor: '#fff',
          color: '#000',
        },
        logo: {
          height: '40px',
          marginRight: theme.spacing(2),
        },
        title: {
          flexGrow: 1,
          textAlign: 'center',
        },
        button: {
          color: '#000',
          marginRight: theme.spacing(2),
          '&:hover': {
            backgroundColor: '#ccc',
          },
        },
      }));
  
  const classes = theme;

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Link to="/">
          <img src="/logo.png" alt="Logo" className={classes.logo} />
        </Link>
        <Typography variant="h6" className={classes.title}>
          Aulas Particulares
        </Typography>
        <div>
          <Button variant="contained" className={classes.button}>
            Criar conta
          </Button>
          <Button variant="contained" className={classes.button}>
            Entrar
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;