import { useContext, useState } from 'react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { Payment, CardPayment } from '@mercadopago/sdk-react';
import ApiCall from '../../services/api';
import { VariaveisContext } from '../../services/variaveisProvider';
import { Box, Button } from '@mui/material';
import Loading from '../gerais/Loading/Loading';




function MercadoPagoPagamento (props) {
  
  initMercadoPago('APP_USR-3c84855c-4269-4a57-878e-e70f0195c0b5', {locale: 'pt-BR'});
  //initMercadoPago('TEST-f9b0bc51-d232-49b5-85e1-49de28d470a0', {locale: 'pt-BR'});

    const { loading } = useContext (VariaveisContext);
    const [finalizado, setFinalizado] = useState(false); 
    const [carregando, setCarregando] = useState (true);

    const customization = {
      visual: {
          defaultPaymentOption: {
          creditCardForm: true,
        },

        texts: {
          paymentMethods: {
          creditCardValueProp: 'Aprovado na hora',
          }
          
        }
      },
        
        paymentMethods: {
          
         
          creditCard: 'all'
        },
        
       };
       
    
    const initialization = {
        payer:
          {       
           email: 'brunocfernandes@yahoo.com.br'
          },
          
        amount: props.dados.valor,
        
       };
      
       
       const onSubmit = async ({formData}
       ) => {
        // callback chamado ao clicar no botão de submissão dos dados
        console.log ('formData: ', formData);
        let b = props.dados;
        let d = {...formData, ...b};
               
      
        const result = await ApiCall ('aulasPagarComCartaoRota',d, loading);
        
        setFinalizado (true);
        props.onFinish(result.newLoading);
      
      };
       
      const onError = async (error) => {
        // callback chamado para todos os casos de erro do Brick
        console.log(error);
       };
      
       const onReady = async () => {
        setCarregando (false);
        /*
          Callback chamado quando o Brick estiver pronto.
          Aqui você pode ocultar loadings do seu site, por exemplo.
        */
       };
  return <> 
  <Loading open={carregando} />
  <Box fullWidth sx={{ px: 3, maxWidth:'100%',  marginBottom: 0, 
                     borderRadius: '4px', border: 'solid', borderWidth: '1px', borderColor: 'rgba(0,0,0,0.12)', paddingY: '1em',
                    }} variant="outlined">
  
  <Payment
    initialization={initialization}
    customization={customization}
    onSubmit={onSubmit}
    onReady={onReady}
    onError={onError}
  />
    
</Box>
</>

} export default MercadoPagoPagamento;