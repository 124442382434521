import { Navigate } from "react-router-dom";
import { localStorageUsuarioName } from "../../../data/config/config";

function PrivateRoute(props){
    const existeLocalStorage = localStorage.getItem(localStorageUsuarioName) ? true : false;
    let logado = false;
    
    if (existeLocalStorage) {
         const logadoTemp = JSON.parse (localStorage.getItem(localStorageUsuarioName)).token ? true : false;
            logado = logadoTemp;
    } else {
            logado = false;
    }

    return logado ? props.children : <Navigate to="/login" />;
}

export default PrivateRoute;