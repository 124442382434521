import { useState, useRef, useContext } from 'react';
import ModalSucessoErro from '../gerais/ModalSucessoErro/ModalSucessoErro';
import { VariaveisContext } from '../../services/variaveisProvider'; 
import Loading from '../gerais/Loading/Loading';
import ApiCall from '../../services/api';
import { styled } from '@mui/material/styles';

import { InputAdornment, IconButton, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { validaEmail2 } from './valida';


function ResetarSenha (props) {

    //const { dados } = props;    
    const [loading, setLoading] = useState ({inicio: false, fim: false, isSuccess: false, msg: 'Checando a validade do email...', response: null, error: null,  modalTipoGeral: 'snack', modalMsg: 'Erro desconhecido', modalVariant: "filled", modalTipo: "success", modalIsAlert: true, modalTimeout: 3000});
    const [estado, setEstado] = useState(0); // em qual parte do cancelamento está o usuário (0 - email, 1 - Loading, 2 - código, 3 - Loading, 4 - senha, 5 - loading , 6 - msgfinal)
    const [titulo, setTitulo] = useState('Email');
    const [subtitulo, setSubtitulo] = useState('Digite o seu email para receber o código de confirmação');
    const [code, setCode] = useState(''); // código de confirmação enviado por email [0] a [5
    const [senha, setSenha] = useState('');
    const [repetirSenha, setRepetirSenha] = useState('');
    const [open, setOpen] = useState(true);
    const [msg, setMsg] = useState('');
    const [senhaIgual, setSenhaIgual] = useState(true);
    const [emailValido, setEmailValido] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
 
    const inputAtual = useRef ();
    const [change, setChange] = useState (false);
    
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    async function EnviarEmailServidor () {
        setEstado (1);
        setLoading ({...loading, inicio: true, fim: false})
        const res = await ApiCall ('senhaSolicitarResetRota', {email: msg}, loading);
        setLoading (res.newLoading);
        if (res.newLoading.isSuccess) {
            setTitulo ('Código');
            setSubtitulo ('Digite o código de confirmação enviado para o seu email');
            setLoading ({...loading, inicio: false, fim: false, msg: 'Confirmando o código...'})
            setEstado (2);
        }       
    }
    
    async function EnviarCodigoServidor () {
        setEstado (3);
        setLoading ({...loading, inicio: true, fim: false})
        const res = await ApiCall ('senhaValidarTokenRota', {email: msg, token: code}, loading);
        setLoading (res.newLoading);
        if (res.newLoading.isSuccess) {
            setTitulo ('Nova senha');
            setSubtitulo ('Digite a nova senha');
            setLoading ({...loading, inicio: false, fim: false, msg: 'Cadastrando a nova senha...'})
            setEstado (4);
        } 
    }
    
    async function EnviarSenhaServidor () {
        setEstado (5);
        setLoading ({...loading, inicio: true, fim: false})
        const res = await ApiCall ('senhaEditarSenhaRota', {email: msg, token: code, senha: senha}, loading);
        setLoading (res.newLoading);
        if (res.newLoading.isSuccess) {
            setLoading ({...loading, inicio: false, fim: false, msg: 'Cadastrando a nova senha...'})
            setEstado (6);
        } 
    }
    
    
    function handleFechamento (e) {
        e.preventDefault ();
        setSenha ('');
        setRepetirSenha ('');
        setCode ('');
        setMsg ('');
        setEstado (-1);
        setOpen (false);
        props.onClose();
    }   

    return <>
    {estado === 0 || estado === 2 || estado === 4 ? 
        
        <Dialog open={open}>
        <DialogTitle>{titulo}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {subtitulo}
          </DialogContentText>
          <Box sx={{marginTop: '2em'}}>
          {estado === 0 ?     
          <>
          <TextField
            onChange={(e) => setMsg (e.target.value)}
            fullWidth
            type="email"
            id="mensagem"
            label="Email"
            variant="outlined"
            autoFocus
            error={!(validaEmail2  (msg) || msg ==='')}
            helperText={!(validaEmail2  (msg) || msg ==='') ? 'Não é um email válido' : ''}
            />
          </> : null}
          
          {estado === 2 ? <>
            <TextField
            onChange={(e) => setCode (e.target.value)}
            fullWidth
            type="text"
            id="codigo"
            label="Código de 6 dígitos"
            variant="outlined"
            autoFocus
            inputProps={{minlength: 6, maxlength: 6, style: {fontSize: 25}}}
            />
            </> 
          : null}   
            
          {estado === 4 ? <>
            <TextField
            onChange={(e) => setSenha (e.target.value)}
            fullWidth
            id="senha"
            label="Nova senha"
            variant="outlined"
            autoFocus
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }}
            />
            <TextField
            sx = {{marginTop: '1.5em'}}
            onChange={(e) => setRepetirSenha (e.target.value)}
            fullWidth
            id="repetirsenha"
            label="Repetir senha"
            variant="outlined"
            
            
            type={showPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }}
                error={!(senha===repetirSenha || senha === repetirSenha === '' || (senha !== '' && repetirSenha === ''))}
                helperText={!(senha===repetirSenha || senha === repetirSenha === '' || (senha !== '' && repetirSenha === '')) ? 'As senhas devem ser iguais.' : ''}
            />
            </>
           : null}
          
            </Box>
        </DialogContent>
        
        <DialogActions>
          <Button onClick={(e) => {handleFechamento (e)}}>Cancelar</Button>
          
          {estado === 0 ? estado === 0 && validaEmail2  (msg) ? <Button onClick={() => {EnviarEmailServidor()}} >Enviar</Button> : <Button disabled>Enviar</Button> : null}
          {estado === 2 ? estado === 2 && code.length === 6   ? <Button onClick={() => {EnviarCodigoServidor()}}>Enviar</Button> : <Button disabled>Enviar</Button> : null}
          {estado === 4 ? (senha === repetirSenha) ? <Button onClick={() => {EnviarSenhaServidor()}} >Enviar</Button> : <Button disabled>Enviar</Button> : null}
          
        </DialogActions>
      </Dialog>

    : null}
    
    { estado === 1 || estado=== 3 || estado=== 5 ? <Loading open={loading.inicio} msg={loading.msg}></Loading> : null}
    
    {(estado === 6) && loading.isSuccess && loading.fim ? <ModalSucessoErro open={true} tipo='success' msg={loading.modalMsg} btn1='OK' onClickBtn1={(e) => handleFechamento(e)} /> : null}
    {(estado === 1 || estado === 3 || estado === 5) && !loading.isSuccess && loading.fim ? <ModalSucessoErro open={true} tipo='error' msg={loading.modalMsg}
                                          btn1='Fechar' onClickBtn1={(e) => handleFechamento(e)} /> : null}
    
    </>
} export default ResetarSenha;
