
import { Dialog, DialogContent, Box, CircularProgress } from '@mui/material';

function Loading (props) {


return <>
    <Dialog open={props.open}>
        <DialogContent>
            <Box display="flex" alignItems="center">
                    <CircularProgress size={40} />
                    {props.msg === undefined ? null : <Box ml={3} sx={{fontSize: '1.1em'}}>{props.msg}</Box>}
           </Box>
        </DialogContent>
    </Dialog>
</>
}
export default Loading;