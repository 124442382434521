import { useContext, useRef } from 'react';
import Payment from '../../components/Payment/Payment';
import PaymentResumo from '../../components/Payment/PaymentResumo';
import { VariaveisContext } from '../../services/variaveisProvider';
import { AulaMarcada } from './funcoes';
import { Button, Box, Grid} from '@mui/material'
import MercadoPagoPagamento from '../../components/MercadoPago/Pagamento';

function PaymentPage({setEstado, handleHorarioNaoDisponivel }) {
   const { horariosDoDia, selectedAssuntos, usuario } = useContext(VariaveisContext);
   const aulaMarcada = useRef (AulaMarcada (usuario.current.id, horariosDoDia, selectedAssuntos)); // dados que serão enviados para o backend

   function onBackPayment () {
     setEstado (1);
   };

   
return (
  <>  
   
    {aulaMarcada !== undefined && aulaMarcada !== null  ?
       <>
              
              
             <div style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '1em',
              marginTop: '1.0em',
          }}>
              
              <div className="row mx-auto mt-3 gap-3 gap-xl-0 " > 
              <div className="col-12 col-xl-4 ">
              <PaymentResumo aulaMarcada = {aulaMarcada} />
              </div>
    
              <div className="col-12 col-xl-8 ">
              <Payment aulaMarcada = {aulaMarcada} onBackPayment={() => setEstado (1)} handleHorarioNaoDisponivel={(result) => handleHorarioNaoDisponivel (result)} />
              
              </div>
              <div className='d-flex justify-content-center'>
              <Button sx = {{mt: 4}} variant="outlined" onClick={(e) => {onBackPayment()}}>Voltar</Button>  
              </div>
              </div>
              
              </div>
        
       </> : null
    }
  </>
  );
}

export default PaymentPage;