import React, { useState, useEffect } from 'react';
import { Link, Grid, CircularProgress, TextField, Button, Avatar, Typography, InputAdornment, IconButton } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { AtualizaCampos, validarFormularioLogin, validarEmail } from './valida';
import Copyright from '../Copyright/Copyright';
import { COPYRIGHT_NOME, COPYRIGHT_SITE } from '../../data/config/config';
import ResetarSenha from './ResetarSenha';

function Login({ dadosLogin, setDadosLogin, alterarParaCriarConta, isLoading, handleLogin, estado }) {
  
    const [botaoLigado, setBotaoLigado] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [emailValido, setEmailValido] = useState(true);
    const [openResetar, setOpenResetar] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {event.preventDefault()};

    useEffect(() => {
      validarEmail (dadosLogin.email, setEmailValido)
      validarFormularioLogin (dadosLogin, emailValido, setBotaoLigado); 
  }, [dadosLogin]);

  
  function esqueciSenha() {
    setOpenResetar(true);
  }

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>

      <Typography component="h1" variant="h5">
        Entrar
      </Typography>

      <TextField
        disabled={isLoading}
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoFocus
        autoComplete="email"
        value={dadosLogin.email}
        onChange={(e) => AtualizaCampos(e, estado, setDadosLogin, null, setEmailValido)}
        error={!emailValido}
        helperText={!emailValido ? 'Não é um email válido' : ''} />

      <TextField
        disabled={isLoading}
        required
        fullWidth
        margin="normal"
        name="senha"
        label="Senha"
        autoComplete="current-password"
        value={dadosLogin.senha}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>,
        }}

        onChange={(e) => AtualizaCampos(e, estado, setDadosLogin)} />


      <Button
        disableElevation
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        disabled={!botaoLigado || isLoading}
        onClick={(event) => handleLogin(event)}
      >
        {isLoading ? <CircularProgress color="primary" sx={{ padding: 1 }} /> : "Entrar"}
      </Button>

      <Grid container justifyContent="space-between" sx={{ mb: 4 }}>

        <Grid item>
          <Link href="#" variant="body2" onClick={esqueciSenha}>
            {isLoading ? null : "Esqueceu a senha?"}
          </Link>
        </Grid>

        <Grid item>
          <Link href="#" variant="body2" onClick={(e) => alterarParaCriarConta(e)}>
            {isLoading ? null : "Clique para criar sua conta!"}
          </Link>
        </Grid>
      </Grid>


      <Copyright nome={COPYRIGHT_NOME} site={COPYRIGHT_SITE} />
      

      {openResetar ? <ResetarSenha onClose={() => setOpenResetar(false)} /> : null}
    </>
  );
}

export default Login;