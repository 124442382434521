import { useState, useContext, useRef } from 'react';
import ModalSucessoErro from '../gerais/ModalSucessoErro/ModalSucessoErro';
import { VariaveisContext } from '../../services/variaveisProvider'; 
import Loading from '../gerais/Loading/Loading';
import ApiCall from '../../services/api';

function CancelamentoComPagamento (props) {

const { loading, setLoading } = useContext (VariaveisContext);    
const [estado, setEstado] = useState(0); // em qual parte do cancelamento está o usuário (0 - tela de confirmacao, 1 - Loading, 2 - msg final)
const [open, setOpen] = useState(true);
const etapa = useRef ({cancelamento: false, servidor: false});

async function CancelarAula () {
    // ETAPA 1 - CANCELAMENTO NO PAGSEGURO

    const res = await ApiCall ('aulasCancelarComPagamento', props.dados, loading);
    setLoading (res.newLoading);
    
    if (res.newLoading.isSuccess) {
        if (res.newLoading.response.data.msg === 'Estorno do valor realizado com sucesso!') {
        etapa.current.cancelamento = true;
        etapa.current.servidor = true;
        setEstado (2);}
        else {
        setLoading ({...loading, modalMsg: res.newLoading.error.data.msg})
        etapa.current.cancelamento = false;
        etapa.current.servidor = false;
        setEstado (2);
        }
    } else {
        setEstado (2);
    }
}

function handleFechamento () {
    setEstado (-1);
    setOpen (false);
    props.setOpenCancelamento(false);
}
return <>
    {estado === 0 ? 
    <ModalSucessoErro open={open} tipo='warning' msg='Tem certeza que deseja cancelar a aula?' btn1='Sim' btn2='Não' onClickBtn1={() => {setEstado (1); CancelarAula ()}} onClickBtn2={() => handleFechamento ()} />
    : null}
    {estado === 1 ? <Loading open={open} msg='Cancelando a aula...'></Loading> : null}
    {estado === 2 && loading.isSuccess ? <ModalSucessoErro open={true} tipo='success' msg='Aula cancelada com sucesso! A devolução do valor é feita pelo próprio MercadoPago e o prazo é de 1 a 2 faturas do cartão.' btn1='OK' onClickBtn1={() => window.location.reload()} /> : null}
    {estado === 2 && !loading.isSuccess ? <ModalSucessoErro open={true} tipo='error' msg={loading.modalMsg} 
    btn1='Fechar' onClickBtn1={() => handleFechamento()} /> : null}
</>
} export default CancelamentoComPagamento;