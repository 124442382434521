import { useState, useContext } from 'react';
import ModalSucessoErro from '../gerais/ModalSucessoErro/ModalSucessoErro';
import { VariaveisContext } from '../../services/variaveisProvider'; 
import Loading from '../gerais/Loading/Loading';
import ApiCall from '../../services/api';

function CancelamentoSemPagamento (props) {

const { loading, setLoading } = useContext (VariaveisContext);    
const [estado, setEstado] = useState(0); // em qual parte do cancelamento está o usuário (0 - tela de confirmacao, 1 - Loading, 2 - msg final)
const [open, setOpen] = useState(true);

async function CancelarAulaServidor () {
    const res = await ApiCall ('cancelarAulaSemPagamento', {id: props.aulaId}, loading);
    setLoading (res.newLoading);
    setEstado (2);

   
}

function handleFechamento () {
    setEstado (-1);
    setOpen (false);
    props.setOpenCancelamento(false);
}
return <>
    {estado === 0 ? 
    <ModalSucessoErro open={open} tipo='warning' msg='Tem certeza que deseja cancelar a aula?' btn1='Sim' btn2='Não' onClickBtn1={() => {setEstado (1); CancelarAulaServidor ()}} onClickBtn2={() => handleFechamento ()} />
    : null}
    {estado === 1 ? <Loading open={open} msg='Cancelando a aula...'></Loading> : null}
    {estado === 2 && loading.isSuccess ? <ModalSucessoErro open={true} tipo='success' msg='Aula cancelada com sucesso!' btn1='OK' onClickBtn1={() => window.location.reload()} /> : null}
    {estado === 2 && !loading.isSuccess ? <ModalSucessoErro open={true} tipo='error' msg='Aula não cancelada - não foi possível a conexão com os nossos servidores. Tente novamente mais tarde.' btn1='Fechar' onClickBtn1={() => handleFechamento()} /> : null}
</>
} export default CancelamentoSemPagamento;