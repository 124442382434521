import { useState, useEffect } from 'react';

import { Card, CardContent, TextField, Button, Box, Grid, InputAdornment, IconButton }  from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { AtualizaCampos, RetornaDadosUsuario, validarEmail, validarFormulario } from './funcoes';

function ConfigUsuario({ atualizarUsuario }) {
  const [dadosUsuario, setDadosUsuario] = useState(RetornaDadosUsuario());
  const [dadosCampos, setDadosCampos] = useState(RetornaDadosUsuario());
  const [botaoLigado, setBotaoLigado] = useState(false);
  const [emailValido, setEmailValido] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  
  useEffect(() => {
    validarEmail (dadosCampos.email, setEmailValido)
    validarFormulario(dadosCampos, dadosUsuario, emailValido, setBotaoLigado);
  }, [dadosCampos]);

    
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => { event.preventDefault(); };

  return (
    <Card variant="outlined">
      <CardContent>
        <h5 className="mb-1">Seus dados</h5>
        <hr></hr>
        
        <Grid container spacing={2}>
          
          <Grid item xs={12} md={6}>
            <TextField
              
              fullWidth
              required
              margin="normal"
              label="Nome"
              name="nome"
              value={dadosCampos.nome}
              onChange={(e) => AtualizaCampos (e, setDadosCampos)} />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField

              fullWidth
              required
              margin="normal"
              label="Sobrenome"
              name="sobrenome"
              value={dadosCampos.sobrenome}
              onChange={(e) => AtualizaCampos (e, setDadosCampos)} />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField

              fullWidth
              required
              margin="normal"
              label="Email"
              name="email"
              value={dadosCampos.email}
              error={!emailValido}
              helperText={!emailValido ? 'Não é um email válido' : ''}
              onChange={(e) => AtualizaCampos (e, setDadosCampos)} />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField

              fullWidth
              required
              margin="normal"
              label="Senha"
              name="senha"
              type="password"
              value={dadosCampos.senha}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }}
              onChange={(e) => AtualizaCampos (e, setDadosCampos)} />
          </Grid>
        
        </Grid>
        
        <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={!botaoLigado}
            onClick={(event) => atualizarUsuario(event, dadosCampos)}
          >
            Atualizar
          </Button>
        
        </Box>
      
      </CardContent>
    
    </Card>
  );
}

export default ConfigUsuario;
