import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Button, FormControlLabel, FormGroup, Grid, TextField } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import assuntos from '../../data/constants/assuntos';
import { VariaveisContext } from '../../services/variaveisProvider';

function Assunto({ onBackAssuntos, onAgendar }) {

    
  const { selectedAssuntos } = useContext(VariaveisContext);
  const [personName, setPersonName] = useState(selectedAssuntos.current.assuntos);
  const [modeloAula, setModeloAula] = useState(selectedAssuntos.current.modelo);
  const [comentarios, setComentarios] = useState(selectedAssuntos.current.comentarios);
  

  // quando o usuário selecionar um assunto, atualiza o estado
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

  };

  // quando o usuário selecionar o modelo de aula
  const handleModeloAulaChange = (event) => {
    setModeloAula({ [event.target.value]: event.target.checked });
  };

  // quando o usuário escrever no campo de comentários
  const handleComentariosChange = (event) => {
    setComentarios(event.target.value);
  };

  useEffect(() => {
    selectedAssuntos.current.assuntos = [...personName];
    selectedAssuntos.current.modelo = Object.keys(modeloAula)[0];
    selectedAssuntos.current.comentarios = comentarios;
  }, [personName, modeloAula, comentarios]);


  return (
    <div className=' mt-3'>

      <FormControl fullWidth >

        <Grid container spacing={2} >

          <Grid item xs={12} lg={6} >
            <h5 className='ms-2  '>Escolha os assuntos da aula</h5>

            <InputLabel sx={{ mt: 4, ml: 2 }} id="assuntos-label">Assuntos</InputLabel>

            <Select
              fullWidth
              shink
              labelId="assuntos-label"
              id="assuntos-select"
              multiple
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {assuntos.map((assunto, index) => (
                <MenuItem key={index} value={assunto}>
                  {assunto}
                </MenuItem>
              ))}
            </Select>

          </Grid>

          <Grid item xs={12} lg={6} >
            <h5 className=' ms-2'>Escolha o modelo da aula</h5>

            <FormGroup>
              <Box sx={{
                display: 'flex', flexDirection: 'column', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px',
                borderTopLeftRadius: '4px', borderTopRightRadius: '4px', boxSizing: 'border-box', borderStyle: 'solid', borderWidth: '1px', borderColor: '#b5babf', paddingTop: '5px', paddingBottom: '5px',
                paddingLeft: '10px', paddingRight: '10px'
              }}>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={"teoria"}
                  onClick={handleModeloAulaChange}
                >

                  <FormControlLabel value="teoria" control={<Radio />} label="Teoria apenas" />
                  <FormControlLabel value="teoriaexerc" control={<Radio />} label="Teoria e exercícios" />

                </RadioGroup>
              </Box>

            </FormGroup>
          </Grid>

        


        <Grid ml={2} xs={12}>
          <h5 className='mt-4 ms-2'>Insira os seus comentários</h5>
          <TextField
            label="Adicione mais informações sobre a aula"
            multiline
            rows={4}
            fullWidth
            
            onChange={handleComentariosChange} />
        </Grid>
      </Grid>
      </FormControl>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <Button sx={{ marginRight: '2rem' }} variant="outlined" onClick={onBackAssuntos}>
          Voltar
        </Button>

        <Button variant="contained" disableElevation onClick={onAgendar}>
          Agendar
        </Button>
      </div>
    </div>

  );
}

export default Assunto;
