
function HorariosEscolhidosCompleto (horarios) {
    let horariosEscolhidosCompleto = horarios.filter((horario) => horario.selecionado === "s");
    return horariosEscolhidosCompleto;
}

function HorariosEscolhidosID (horarios) {
    let horariosEscolhidosID = horarios.map((horario) => horario.id);
    return horariosEscolhidosID;
}

function HorariosEscolhidosInicioFim (horarios) {
    let horariosEscolhidosCompleto = horarios.filter((horario) => horario.selecionado === "s");
    let horariosEscolhidosInicioFim = horariosEscolhidosCompleto.map((horario) => horario.inicio + " - " + horario.fim);
    return horariosEscolhidosInicioFim;
}

function Data (horarios) {
    let data = horarios[0].data;
    return data;
}

function Inicio (horarios) {
    return horarios[0].inicio;
}

function Fim (horarios) {
    if (horarios.length === 1) {return horarios[0].fim} else
    {return horarios[1].fim
    }
}

function Valor (horarios) {
    let valor = 0;
    horarios.map((horario) => {
        valor = valor + horario.valor;
    });
    if (horarios.length === 2) {valor = valor - (valor * horarios[0].desconto / 100)};
    return valor;
}

export function AulaMarcada (usuario, horariosDoDia, assuntos, pedido) {
    
    let horariosEscolhidosCompleto = {}; 
    
    horariosEscolhidosCompleto.idUsuario = usuario;
    horariosEscolhidosCompleto.idHorarios = HorariosEscolhidosID (HorariosEscolhidosCompleto (horariosDoDia));
    horariosEscolhidosCompleto.data = Data (HorariosEscolhidosCompleto (horariosDoDia));
    horariosEscolhidosCompleto.inicioFim = HorariosEscolhidosInicioFim (horariosDoDia);
    horariosEscolhidosCompleto.valor = Valor (HorariosEscolhidosCompleto (horariosDoDia));
    horariosEscolhidosCompleto.assuntosAula = assuntos.current.assuntos;
    horariosEscolhidosCompleto.modeloAula = assuntos.current.modelo;
    horariosEscolhidosCompleto.comentariosAula = assuntos.current.comentarios;
    horariosEscolhidosCompleto.reference_id = 'AP' + horariosEscolhidosCompleto.idHorarios[0]; 
    
    return horariosEscolhidosCompleto
}