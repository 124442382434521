import React from 'react';
import { pt } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';


function Calendar(props) {

  const [dia, setDia] = React.useState(new Date(props.selectedDate).toLocaleString('pt-BR', { weekday: 'long' }));
   

  const handleDateChange = (date) => {
    props.onDateSelect(date);
    setDia(date.toLocaleString('pt-BR', { weekday: 'long' }));
  };

  return (
    <div>
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '1.5em'}}>
    <h5>{dia}</h5>
    </div>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
    
      <DateCalendar disablePast={true} value={new Date(props.selectedDate)} views={['day']} onChange={(newDate) => handleDateChange(newDate)}>
      </DateCalendar>
      
    </LocalizationProvider>
    
    </div>
  );
}

export default Calendar;
