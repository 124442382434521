import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Typography, Box } from '@mui/material';

const CallToAction = () => {

  const navigate = useNavigate();

  function btnClick(e) {
    e.preventDefault();
    navigate('/login');
  }
  
  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
    <Box sx={{ backgroundColor: '#F8F8F8', padding: '2rem', marginTop: '1em' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Marque já a sua aula com o professor Bruno Fernandes e comece a aprender Física de forma prática e eficiente!
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <Button variant="contained" color="primary" size="large" onClick={(e) => btnClick(e)}>
          Agende sua aula agora!
        </Button>
      </Box>
    </Box>
    </Container>
  );
};

export default CallToAction;