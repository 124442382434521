import { Container, Grid, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import BF from '../../../data/images/students_happy.jpg';

const Beneficios = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 1 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h2" gutterBottom>
            Benefícios de ter aula com o BF
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <CheckCircleOutline fontSize="large" color="success" sx={{ mr: 1 }} />
            Aprenda com um professor experiente em diversos segmentos educacionais.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            <CheckCircleOutline fontSize="large" color="success" sx={{ mr: 1 }} />
            Receba suporte individualizado para suas dúvidas em Física.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            <CheckCircleOutline fontSize="large" color="success"  sx={{ mr: 1 }} />
            Acesse materiais didáticos exclusivos e conteúdo de qualidade.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            <CheckCircleOutline fontSize="large" color="success"  sx={{ mr: 1 }} />
            Desfrute de aulas online práticas e eficientes.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            <CheckCircleOutline fontSize="large" color="success"  sx={{ mr: 1 }} />
            Aprenda de onde estiver, sem perder tempo com deslocamento.
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            <CheckCircleOutline fontSize="large" color="success"  sx={{ mr: 1 }} />
            Pague de forma segura e prática com cartão de crédito.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
          
          
          <img src={BF} alt="Estudantes felizes estudando Física" height="300" width="400" sx={{ borderRadius: 2, mt: 2 }} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Beneficios;