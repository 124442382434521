import React, { useEffect, useContext, useState } from 'react';

import { Grid,  Alert, AlertTitle, Card, CardContent, Typography, Stepper, Step, StepLabel } from '@mui/material';

import Calendar from '../../components/Calendar/Calendar';
import TimeSlots from '../../components/TimeSlots/TimeSlots.jsx';
import Assuntos from '../../components/Assuntos/Assuntos'; // Passo 2

import PaymentPage from '../PaymentPage/PaymentPage';
import ApiCall from '../../services/api';
import Loading from '../../components/gerais/Loading/Loading';
import { SCHEDULE_LESSON_PAGE_TITLE } from '../../data/config/config';
import { VariaveisContext } from '../../services/variaveisProvider';
import { RetornaHorariosDoDia, PrimeiraDataDisponivel, ZerarObjetoHorariosDoDia, RetornaMsg, FormatarData } from './funcoes';
import ModalSucessoErro from '../../components/gerais/ModalSucessoErro/ModalSucessoErro';
import { maxWidth } from '@mui/system';
import NavBar from '../../components/NavBar/NavBar';


function ScheduleLesson() {


  const [estado, setEstado] = useState(0); // em qual parte do agendamento está o usuário (0 = dia e hora, 1 = assuntos, 2 = pagamento)
  const {usuario, selectedAssuntos, liberadoParaPagamento, mudouNaoPago, setMudouNaoPago,  horariosDoDia, setHorariosDoDia, selectedDate, horarioClicado, setData, loading, setLoading, horarios, setHorarios} = useContext (VariaveisContext); 
  const [isLoading, setIsLoading] = useState({...loading}); 
  
  
  const steps = ['Dia e horário', 'Assuntos', 'Pagamento'];

  // chama a API toda vez que retornar para os Horários (estado 0)
  useEffect(() => {
    
    setLoading ({...loading, inicio: true, msg: 'Carregando horários...'});

    const getResult = async () => {
      let a = {idUsuario : usuario.current.id}
      const result = await ApiCall("listarHorarios", a, loading); // chama a API de login
      
      if (result.newLoading.isSuccess) {
        setHorarios (result.newLoading.response.data);
        let horariosTemp = result.newLoading.response.data;
        horariosTemp.forEach((data) => {data.selecionado = "n"})
        horariosTemp = FormatarData (horariosTemp);
        let a = PrimeiraDataDisponivel(horariosTemp);
        setData ({selectedDate: a, primeiroDiaDisponivel: a});
        RetornaHorariosDoDia(a, horariosTemp, setHorariosDoDia);
        setHorarios (horariosTemp);
        setLoading (result.newLoading); // Atualiza o estado de loading
        selectedAssuntos.current = {assuntos: [], modelo: "teoria", comentarios: ""};
        } else setLoading (result.newLoading); // Atualiza o estado de loading
      }  
       
      getResult();
}, []);

  // altera a data no calendário e carrega os horários disponíveis para a data selecionada
     const handleDateChange = (date) => {
      setData ({selectedDate: date});
      
      ZerarObjetoHorariosDoDia (horariosDoDia, setHorariosDoDia, horarioClicado)
      RetornaHorariosDoDia(date, horarios, setHorariosDoDia);
    };
  
  // lida com o resultado da consulta a API sobre o horario disponivel
      function handleHorarioDisponivel (result) {
        let res = false;
        setIsLoading (result.newLoading); // Atualiza o estado de loading         
          if (result.newLoading.isSuccess) 
            {// se o horário foi atualizado com sucesso, verifica se há mudança nas aulas não pagas. Caso haja atualizar estado de mudouNaoPago para poder resetar a página
              
              if (result.newLoading.response.data.length >  0) {
                let a = RetornaMsg (result.newLoading.response.data, horariosDoDia);
                
                setIsLoading ({...result.newLoading, modalMsg: a});
                
                setMudouNaoPago (true);
              } else {
                  setMudouNaoPago (false);
                  if (estado === 0) {
                    setEstado (1);
                  } else {
                    if (estado === 1) {
                    setEstado (2);
                  } else {
                    if (estado === 2) {
                      liberadoParaPagamento.current = true;
                      res = true;
                      
                  }
              
              }
            }
          } 
            } else {
              if (estado === 0) setEstado (1);
              if (estado === 1) setEstado (2);
            }
          return res;
          }
  // chama a API para conferir se os horarios estão disponíveis
     async function conferirHorarioDisponivel () {
         setIsLoading ({...isLoading, inicio: true, msg: 'Conferindo disponibilidade dos horários...'});
         let a = {idHorarios: horarioClicado.current};
         
         const result = await ApiCall('conferirHorarioDisponivel', a, isLoading); // chama a API de login
          handleHorarioDisponivel (result);
         
     }  
  
  return <>

  <NavBar /> 
  
  {(loading.inicio || !isLoading) ? <Loading open={loading.inicio} msg={"Carregando horários..."} /> : null}
    
  <div className="container mb-4 mt-4">

  
        <Typography sx={{ marginLeft: '5px', marginBottom: '15px' }} variant="h5" component="div">
            {SCHEDULE_LESSON_PAGE_TITLE}
        </Typography>
        
        <Card>
        <CardContent >
            <h5 className="mb-1">Escolha as suas preferências</h5>
            <hr></hr> 
 
         
                <Stepper sx={{marginTop:'2em'}}  activeStep={estado} alternativeLabel>
                  {steps.map((label) => (<Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>))}
                </Stepper>
        
   

  {estado === 0 && loading.fim && !loading.isSuccess ? <>
    <Alert sx={{marginTop: "3em"}}severity="error">
               <AlertTitle>Erro</AlertTitle>
          {loading.modalMsg}
          </Alert> 
  </> : null}

  {estado === 0 && loading.fim && loading.isSuccess ?    
        <>
        
            <Calendar selectedDate = {selectedDate} onDateSelect = {(newDate) => handleDateChange (newDate)} />
        
        <TimeSlots
                selectedDate={selectedDate}
                onClickBtnAssuntos={(e) => {e.preventDefault (); conferirHorarioDisponivel () }}
        />
        
        </>
        : null
  }
  
  {estado === 1 && loading.fim && loading.isSuccess ? 
        <div className='container px-0 px-lg-5' style={{margin: 'auto'}}>
        <Assuntos onBackAssuntos={() => setEstado (0)} onAgendar={(e) => {e.preventDefault (); conferirHorarioDisponivel () }} /> 
        </div>
        : null}
  {estado === 2 && loading.fim && loading.isSuccess ? 
           <div className='container px-0 px-lg-5' style={{margin: 'auto'}}>
           <div className='container px-0 px-lg-5' style={{margin: 'auto'}}>
           <PaymentPage setEstado={setEstado} handleHorarioNaoDisponivel={(result) => handleHorarioDisponivel(result)}  /> 
           </div>
           </div>
        : null}        
   
      </CardContent>
      </Card>
 </div>
  
  {/* MOSTRA ALERTA AFIRMANDO QUE ALGUEM JÁ PAGOU POR UM DETERMINADO HORARIO */}
  <ModalSucessoErro open={mudouNaoPago} tipo="info" onClose={() => setMudouNaoPago (false)} onClickBtn1 = {() => {window.location.reload()}} 
                                  msg={isLoading.modalMsg} />    
  </>


}; export default ScheduleLesson;
