function isEmail(email) {
    const expressaoRegularEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return expressaoRegularEmail.test(email);
  }

function validaEmail(email) {
  if (email !== '') {
    if (isEmail(email)) {
      return true
    } else {
      return false;
    }
  }
}

export function validaEmail2(email) {
  if (email !== '') {
    if (isEmail(email)) {
      return true
    } else {
      return false;
    }
  }
}

export function validarSenha(dado, setSenhaIgual) {

  if (dado.repetirSenha !== '' && dado.senha !== '') {
    if (dado.senha === dado.repetirSenha) {
      setSenhaIgual(true);
    } else {
      setSenhaIgual(false);
    }
  }
}

export function validarEmail (dado, setEmailValido) {

    if (validaEmail(dado) || dado === '') {
      setEmailValido(true);
    } else {
      setEmailValido(false);
    }}

export function AtualizaCampos (event, estado, setDadosLogin, setDadosCriarConta) {
    event.preventDefault();
    const { name, value } = event.target;
    
    if (estado === 0) {
      setDadosLogin((prev) => ({...prev, [name]: value}))};
    
      if (estado === 1) {
      setDadosCriarConta((prev) => ({...prev, [name]: value}))};
  }
    
export function validarFormularioCriarConta (dado, emailValido, setBotaoLigado) {
  if (dado.nome && dado.sobrenome && dado.email && dado.senha && dado.repetirSenha && dado.senha === dado.repetirSenha && !emailValido && dado.email.length !== 1) {
    setBotaoLigado(true);
  } else {
    setBotaoLigado(false);
  }
}

export function validarFormularioLogin (dado, emailValido, setBotaoLigado) {
  if (dado.email && dado.senha && emailValido && dado.email.length !== 1) {
    setBotaoLigado(true);
  } else {
    setBotaoLigado(false);
  }
}

