import React from 'react';
import { NavLink } from 'react-router-dom';

import { logo } from '../../data/config/config'

import { Box, AppBar, Toolbar, Tooltip, Typography, IconButton } from '@mui/material/';
import { styled } from '@mui/system';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: var(--azul-claro);

  &.active {
    color: ${({ theme }) => '#FFFFFF'};
  }
`;

  
  const NavBar = () => {
    return (
      <AppBar position="sticky" sx={{boxShadow: '0'}}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          
           <StyledNavLink to="/" activeClassName="active">
            <Box  >
              <img style={{ width: '40%', height: 'auto', objectFit: 'contain', paddingTop: '0.1em', paddingBottom: '0.1em'}} src={logo} alt="Logo" />
              
            </Box> 
            </StyledNavLink>
            
            <div className='d-none d-md-inline'>
            <Typography variant="h6">Aulas Particulares</Typography>
            </div>
          <div>
          
          
          <Tooltip title="Agendar aula" arrow>
            <IconButton>
              <StyledNavLink to="/" activeClassName="active">
             
              <CalendarMonthOutlinedIcon fontSize="medium" />
                 
             
              </StyledNavLink>
            </IconButton>
            </Tooltip>
            
            
            
            <Tooltip title="Gerenciar aulas" arrow>
            <IconButton>
              <StyledNavLink to="/gerenciamento" activeClassName="active">
              <ManageHistoryOutlinedIcon fontSize="medium" />
                
             
            </StyledNavLink>
          </IconButton>
          </Tooltip>
          
         
      
          <Tooltip title="Perfil" arrow>
          <IconButton>
            <StyledNavLink to="/config" activeClassName="active">
           
           <PersonOutlineOutlinedIcon fontSize="medium"/>
                
           
            </StyledNavLink>
          </IconButton>
          </Tooltip>

          



        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
