const desenvolvimento = 'producao'; // 'desenvolvimento' ou 'producao'

// ************* COPYRIGHT *************
export const COPYRIGHT_NOME = "Professor Bruno Fernandes";
export const COPYRIGHT_SITE = "https://www.professorbrunofernandes.com.br";

// ************* NAVBAR *************
export const logo = require('../images/bf_logo.png');
export const NAVBAR_TITULO = "Aulas Particulares";

// ************ SCHEDULE LESSON PAGE *************
export const SCHEDULE_LESSON_PAGE_TITLE = "Agendar Aula";

// ************* LOCAL STORAGE *************
export const localStorageUsuarioName = "usuarioAulasParticularesBF";

// ************* API *************


export const URLServidorPrincipal = () => {
  if (desenvolvimento === 'desenvolvimento') {
    return 'http://localhost:8082';
  } else {
    return 'https://api.professorbrunofernandes.com.br:3001';
  }
}

const versaoAPI= 'v1';	

export const endpoint = {
  login                       : {url: versaoAPI + '/usuarios/login'},
  logout                      : {url: versaoAPI + '/usuarios/logout'                       , msgServidor: "Logout efetuado com sucesso!"},
  cadastrar                   : {url: versaoAPI + '/usuarios/cadastro'                    , msgServidor: "Cadastro efetuado com sucesso!"},
  alterarUsuario              : {url: versaoAPI + '/usuarios/cadastro/atualizar'          , msgServidor: "Dados atualizados com sucesso!"},
  listarHorarios              : {url: versaoAPI + '/horarios/listar'                       , msgServidor: "Horários listados com sucesso!"},
  enviarMsg                   : {url: versaoAPI + '/mensagens/enviar'                      , msgServidor: "Mensagem enviada com sucesso!"},
  listarAulas                 : {url: versaoAPI + '/aulas/listar'                          , msgServidor: "Aulas listadas com sucesso!"},
  conferirHorarioDisponivel   : {url: versaoAPI + '/horarios/disponibilidade'     , msgServidor: "Horário disponível!"},
  aulasPagarComCartaoRota                   : {url: versaoAPI + '/aulas/pagar/cartao'                       , msgServidor: "Pagamento efetuado com sucesso!"},
  cadastrarAula               : {url: versaoAPI + '/aulas/cadastrar'                       , msgServidor: "Aula cadastrada com sucesso no servidor!"},
  cancelarAulaSemPagamento    : {url: versaoAPI + '/aulas/cancelar/sempagamento'          , msgServidor: "Aula cancelada com sucesso no servidor!"},
  aulasCancelarComPagamento    : {url: versaoAPI + '/aulas/cancelar/compagamento'          , msgServidor: "Aula cancelada com sucesso no servidor!"},
  alterarPagamento            : {url: versaoAPI + '/aulas/alterar/pagamento'             , msgServidor: "Pagamento alterado com sucesso no servidor!"},
  senhaSolicitarResetRota   : {url: versaoAPI + '/usuarios/senha/reset'              , msgServidor: "Email enviado com sucesso!"},
  senhaValidarTokenRota   : {url: versaoAPI + '/usuarios/senha/validartoken'              , msgServidor: "Token validado com sucesso!"},
  senhaEditarSenhaRota   : {url: versaoAPI + '/usuarios/senha/editar'              , msgServidor: "Senha alterada com sucesso!"},
  mercadoPagoPagarCartaoRota : {url: versaoAPI + '/pagamento/mercadopago/cartao/credito'              , msgServidor: "Pagamento efetuado com sucesso!"},
};

// ************* PAGAMENTO *************
export const pagSeguroCartaoObjeto = { 
    amount: {
        value: 0,
        currency: 'BRL'
      },
      description: '',
      reference_id: '123',
      payment_method: {
        type: 'CREDIT_CARD',
        installments: 1,
        capture: true,
        soft_descriptor: '',
        card: {
          exp_year: 2023,
          security_code: 123,
          number: "1111111111111111",
          exp_month: 2,
          holder: { 
            name: "nome"
  }}},
  notification_urls: []
}

  export const pagSeguroCartaoConstantes = {
    moeda: 'BRL',
    tipoPagamento: 'CREDIT_CARD',
    parcelas: 1,
    captura: true,
    descricao: 'Aula Particular',
    descricaoCartao: 'AulaParticular',
  }
