import axios from 'axios';
import { localStorageUsuarioName, endpoint, URLServidorPrincipal } from '../data/config/config';

function RetornaEndpointURL (endpointAlias) {
    return endpoint[endpointAlias].url;
}

function RetornaEndpointMsgServidor (endpointAlias) {
    return endpoint[endpointAlias].msgServidor};

function ProcessarResposta(response) {
  const { data } = response;
  const { status } = response;
  
    
  if (status === 200 || status === 201) {
    
    return { data }};
}

function ProcessarErro(err) {
    let msg = 'Erro desconhecido';
    if (err.hasOwnProperty("response"))
       {
        if (err.response.data.msg !== undefined && err.response.data.msg !== null) {msg = err.response.data.msg;}
        else {
        if (err.response.status === 500) {msg = "Erro interno do servidor. Tente novamente mais tarde."}
        if (err.response.status === 401) {
            msg = "Acesso não autorizado. Faça login novamente."
            localStorage.removeItem(localStorageUsuarioName);
            document.location = "/login";
        }
        if (err.response.status === 408) {msg = "Tempo de requisição esgotado. Tente novamente mais tarde."}
        if (err.response.status === 409) {msg = "Conflito no dados. Tente novamente mais tarde."}}
        
} else {
    msg = "O servidor encontra-se fora do ar. Tente novamente mais tarde.";
}
 
  return msg;
}

async function ApiCall(endpointAlias, data, loading) {
   
    const sessionToken = localStorage.getItem(localStorageUsuarioName) ? JSON.parse (localStorage.getItem(localStorageUsuarioName)).token : null;

    const api = axios.create({
        baseURL: URLServidorPrincipal(),
        headers: { Authorization: `Bearer ${sessionToken}` },
    });
    
        let result = { newLoading: {} };
        let newLoading = {...loading};
         
        try {
            const response = await api.post(RetornaEndpointURL (endpointAlias), data);
            
            newLoading.response = ProcessarResposta(response);
            newLoading.isSuccess = true;
            newLoading.modalMsg = RetornaEndpointMsgServidor (endpointAlias);
            newLoading.modalTipo = "success";
            newLoading.fim = true;
            newLoading.statusCode = response.status;
            newLoading.inicio = false;
            newLoading.fim = true;
        } catch (err) {
                        
            newLoading.error = err;
            newLoading.isSuccess = false;
            newLoading.modalMsg = ProcessarErro(err);
            newLoading.fim = true;
            newLoading.modalTipo = "error";
            newLoading.statusCode = err.response.status;
            newLoading.inicio = false;
            newLoading.fim = true;
        } finally {
           
            result.newLoading = newLoading;
            return result;
        }
    };
 
export default ApiCall;





