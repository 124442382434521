import { Chip } from '@mui/material';
import { grey } from '@mui/material/colors';

export function renderStatusHistorico (status, statuspagamento) {
  const cinza = grey[300];

  switch (status, statuspagamento) {
    case 'agendada', 'pago':
      return <Chip label={'realizada'} color="success" />
      case 'pré-agendada', 'pagamento não aceito':
        return <Chip label={statuspagamento} sx={{ backgroundColor: cinza }} />
        case 'pré-agendada', 'aguardando pagamento':
          return <Chip label={'pagamento não realizado'} sx={{ backgroundColor: cinza }} />
          case 'pré-agendada', 'cancelado':
        return <Chip label={'cancelada'} color="error" />                 
    
}
}

export function renderStatusChip(status) {
    const cinza = grey[300];

    switch (status) {
      case 'encerrada':
        return <Chip label={status} sx={{ backgroundColor: cinza }} />
      case 'aguardando pagamento':
        return <Chip label={status} color="primary" />
      case 'aguardando confirmação do pagamento':
        return <Chip label={status} color="secondary" />
      case 'cancelado':
        return <Chip label={status} color="error" />
        case 'pagamento não aceito':
          return <Chip label={status} color="warning" />
        case 'agendada':
        return <Chip label={status} color="success" />
        case 'pago':
          return <Chip label={status} color="success" />  
        case 'pré-agendada':
        return <Chip label={status} color="secondary" />
      default:
        return <Chip label={status} />;
    }
  }