import { Container, Box } from "@mui/material";
import Beneficios from "./Componentes/Beneficios";
import CallToAction from "./Componentes/CallToAction";
import EspecificacoesTecnicas from "./Componentes/EspecificacoesTecnicas";
import Footer from "./Componentes/Footer";
import Heading from "./Componentes/Heading";
import NavBar from "./Componentes/NavBar";
import Testemunhos from "./Componentes/Testemunhos";

function LandingPage() {
  return (
       <>
              
            <Box sx={{ backgroundColor: '#1976d2', marginTop: '-1.5em' }}>
              <Heading />
            </Box>
            
            <Beneficios />
            
            <Box sx={{ backgroundColor: '#F8F8F8' }}>
              <EspecificacoesTecnicas />
            </Box>

            <Testemunhos />

            <Box sx={{ backgroundColor: '#F8F8F8' }}>
              <CallToAction/>
            </Box>
            
            <Box sx={{ backgroundColor: '#303030' }}>
              <Footer />
            </Box>  
       
        </> 
       
    
  );
} export default LandingPage;