import { localStorageUsuarioName } from '../../data/config/config';
import { VariaveisContext } from '../../services/variaveisProvider';
import { useContext } from 'react';



// Cria armazenamento local para o objeto usuário
export function CriaLocalStorageUsuario () {
    if (!localStorage.getItem(localStorageUsuarioName)) {
        localStorage.setItem(localStorageUsuarioName, JSON.stringify({}));
      }
}

// Troca da tela de login para a tela de criar conta e vice-versa
// Zera os objetos dos campos de login e senha caso troque de login para criar conta. Isso serve para que os dados digitados na tela de login apareçam quando o usuário voltar 
// para a tela de login após ir para a tela de criar conta
export function AlteraEstado (e, estado, setEstado, setDadosLogin, setDadosCriarConta) {
    e.preventDefault();
    setEstado(estado);
    setDadosLogin({ email: '', senha: '' });
    setDadosCriarConta({ nome: '', sobrenome: '', email: '', senha: '', repetirSenha: '' });
}

// Retorna o objeto usuário do armazenamento local
export function RetornaLocalStorageUsuario () {
    return JSON.parse(localStorage.getItem(localStorageUsuarioName));
}

// Atualiza o objeto usuário do armazenamento local
export function AtualizaLocalStorageUsuario (usuario) {
    localStorage.setItem(localStorageUsuarioName, JSON.stringify(usuario));
}   

export function UpdateHorariosSelecionados () {
    const { horariosSelecionados, setHorariosSelecionados } = useContext(VariaveisContext);
    const date = new Date();
    let a = [...horariosSelecionados];
    a.date = date;
    setHorariosSelecionados (a); 
}


