
import pagamentoRespostas from '../../data/constants/pagamento_respostas'
import { pagSeguroCartaoObjeto, pagSeguroCartaoConstantes } from '../../data/config/config'

export function RetornaMsgPagamento (codigo) {

    let msg = '';
    pagamentoRespostas.forEach((item) => {
        if (item.codigo === codigo) {
            msg = item.msg;
        }
    }
    );
    return msg;
}  

export function AutorizadoPagSeguro (data) {
  
  let retorno = {autorizado: false, msg: ''};
    if (data.msg === 'Pagamento realizado com sucesso!') {
        retorno.autorizado = true;
        retorno.msg = data.msg;
    } else {
      retorno.autorizado = false;
      retorno.msg = data.msg;
    }
    return retorno;

}
export function RetornaObjetoPagamentoCartao (valor, referenciaID, parcelas, card) {
  let objeto = {};
  objeto.amount.value = valor;
  objeto.description = pagSeguroCartaoConstantes.descricao;
  objeto.reference_id = referenciaID;
  
  objeto.installments = 1;
  objeto.payment_method.card.holder.name = card.name;
  objeto.payment_method.card.number = card.number;
  objeto.payment_method.card.exp_month = card.expiryMonth;
  objeto.payment_method.card.exp_year = card.expiryYear;
  objeto.payment_method.card.security_code = card.securityCode;
  objeto.payment_method.soft_descriptor = pagSeguroCartaoConstantes.descricaoCartao;

  return objeto;
}

export function getCardBrand(cardNumber) {
    if (/^4/.test(cardNumber)) {
      return "Visa";
    } else if (/^5[1-5]/.test(cardNumber)) {
      return "Mastercard";
    } else if (/^3[47]/.test(cardNumber)) {
      return "American Express";
    } else if (/^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/.test(cardNumber)) {
      return "Maestro";
    } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cardNumber)) {
      return "Discover";
    } else if (/^(30[0-5]|3[68][0-9])[0-9]{11}$/.test(cardNumber)) {
      return "Diners Club";
    } else if (/^35(?:2[89]|[3-8][0-9])[0-9]{12}$/.test(cardNumber)) {
      return "JCB";
    } else {
      return "";
    }
  }

 export  function validarCodigoSeguranca(codigo, tipoCartao) {
    const codigoLength = codigo.length;
    const tipoCartaoRegExp = new RegExp(`^${tipoCartao}$`, 'i');
  
    // Verifica se o código possui a quantidade de dígitos correta
    if (tipoCartaoRegExp.test('Visa') && codigoLength !== 3 && codigoLength !== 4) {
      return false;
    } else if (tipoCartaoRegExp.test('Mastercard') && codigoLength !== 3) {
      return false;
    } else if (tipoCartaoRegExp.test('American Express') && codigoLength !== 4) {
      return false;
    }
  
    // Verifica se o código possui apenas números
    if (!/^\d+$/.test(codigo)) {
      return false;
    }
  
    return true;
  }
  
export  function validarCartao(value) {
  
    // Verificar se o número do cartão possui apenas dígitos
   if (!/^\d+$/.test(value)) {
    return false;
  }
  
    // remove all non digit characters
  var value = value.replace(/\D/g, '');
  var sum = 0;
  var shouldDouble = false;

   
  // loop through values starting at the rightmost side
  for (var i = value.length - 1; i >= 0; i--) {
    var digit = parseInt(value.charAt(i));
    
    if (shouldDouble) {
      if ((digit *= 2) > 9) digit -= 9;
    }

    sum += digit;
    shouldDouble = !shouldDouble;
  }
  return (sum % 10) === 0;
}