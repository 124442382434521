import { Snackbar, Alert, Slide } from "@mui/material";

function SnackbarAviso (props) {
    
    SnackbarAviso.defaultProps = {
           
        isAlert: true,
        time: 3000,
        transition: Slide,
        posicao: { "vertical": "bottom", "horizontal": "center" },
        variant: "filled",
        tipo: "success",
        msg: ""}
     
    
    return <>
    
    <Snackbar open={props.open} autoHideDuration={props.time} 
               TransitionComponent={props.transition}
               anchorOrigin={props.posicao}
               onClose={props.onClose}
      >

              {props.isAlert ? <Alert variant={props.variant} severity={props.tipo} sx={{ width: '100%' }}>
                                    {props.msg}
                             </Alert> : props.msg}
     </Snackbar>
    
     
    
    </>


} export default SnackbarAviso;