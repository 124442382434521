import { useContext, useState } from 'react';
import { VariaveisContext } from '../services/variaveisProvider';

import ConfigUsuario from './../components/ConfigUsuario/ConfigUsuario';
import FormularioContato from './../components/FormularioContato/FormularioContato';
import NavBar from './../components/NavBar/NavBar';

import { Typography } from '@mui/material';

import Loading from '../components/gerais/Loading/Loading';
import ApiCall from '../services/api';

import SnackbarAviso from '../components/gerais/SnackBar/SnackBarAviso';
import { AtualizaLocalStorageUsuario } from '../components/ConfigUsuario/funcoes';
import MercadoPagoPagamento from '../components/MercadoPago/Pagamento';
function ConfigPage() {
  
  const [loading, setLoading ] = useState ({fim: false}) 
  const { usuario } = useContext(VariaveisContext);
  
  // Função para chamar a API de login ou cadastro de nova conta 
  async function callApi(url, data, chamada) {
    const result = await ApiCall(url, data, loading); // chama a API de login

    setLoading(result.newLoading); // Atualiza o estado de loading

    if (result.newLoading.isSuccess && chamada === 'atualizar') {
      AtualizaLocalStorageUsuario(result.newLoading.response.data);
      setTimeout(() => { window.location.reload(); }, loading.modalTimeout + 500);

    }
  }

  function Atualizar(event, dados, chamada) {
    event.preventDefault();
    chamada === 'atualizar' ? setLoading({ ...loading, inicio: true, msg: 'Atualizando dados...' })
      : setLoading({ ...loading, inicio: true, msg: 'Enviando mensagem...' });

    if (chamada !== 'atualizar') {
      let a = dados;
      a.data = new Date().toLocaleDateString('pt-BR');
      a.idUsuario = usuario.current.id;
      callApi("enviarMsg", a, chamada);
    } else {
      let a = dados;
      a.idUsuario = usuario.current.id;
      callApi("alterarUsuario", a, chamada)
    }
       
  }

  
  return (
    <>
    <NavBar />
    <div className="container mb-4 mt-4">



      <Typography sx={{ marginLeft: '5px', marginBottom: '15px' }} variant="h5" component="div">
        Configurações
      </Typography>

      <ConfigUsuario atualizarUsuario={(e, dadosCampos) => Atualizar(e, dadosCampos, "atualizar")} />
      <FormularioContato enviarMensagem={(e, dadosMsg) => Atualizar(e, dadosMsg, "enviar")} />

      {/* Alertas quando o usuário loga com sucesso ou tem erro  */}

      <SnackbarAviso msg={loading.modalMsg} open={loading.fim} onClose={() => setLoading({ ...loading, fim: false })} tipo={loading.modalTipo}></SnackbarAviso>

      {<Loading open={loading.inicio} msg={loading.msg}></Loading>}
    </div>
    </>
  );
}

export default ConfigPage;
