import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScheduleLesson from './pages/ScheduleLesson/ScheduleLesson';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import ScheduleManagementPage from './pages/ScheduleManagementPage';
import NavBar from './components/NavBar/NavBar';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import LoginPage from './pages/Login/LoginPage';
import ConfigPage from './pages/ConfigPage';
import LandingPage from './pages/LandingPage/LandingPage';
import { VariaveisProvider } from './services/variaveisProvider';
import PrivateRoute from './components/gerais/PrivateRoute/PrivateRoute';
import Teste from './pages/teste';


function App() {
  return (
    <Router>
         <VariaveisProvider>
         
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/config" element={<PrivateRoute><ConfigPage /></PrivateRoute>} />
          <Route path="/" element={<PrivateRoute><ScheduleLesson /></PrivateRoute>} />
          <Route path="/pagamento" element={<PrivateRoute><PaymentPage /></PrivateRoute>} />
          <Route path="/gerenciamento" element={<PrivateRoute><ScheduleManagementPage /></PrivateRoute>} />
          <Route path="/page" element={<LandingPage />} />
          <Route path="/teste" element={<Teste />} />
          
          </Routes>  

          </VariaveisProvider>        
    </Router>
  );
}

export default App;
