export function FormatarData (data2) {
  if (data2.length > 0) {
    
    data2.map(d => {
      const [dia, mes, ano] = d.data.split('-');
      const dataFormatada = `${dia}/${mes}/${ano}`;
      d.data = dataFormatada;  
    }
    
    );
    return data2;
}}

export function PrimeiraDataDisponivel (data2) {
    
    const dataAtual = new Date();

    let result = '';
    
    if (data2.length > 0) {
        const [dia, mes, ano] = data2[0].data.split('/');
        const dataFormatada = `${mes}/${dia}/${ano}`;
        result = new Date(dataFormatada);
        
        } else {result = dataAtual};
    return result;    
}

export function RetornaHorariosDoDia (selectedDate, horarios, setHorariosDoDia ) {
    
    let a = selectedDate;
    const dateString = a.toLocaleDateString('pt-BR');
    const selectedDay = horarios.filter((day) => day.data === dateString);
    
    if (selectedDay) {
      setHorariosDoDia(selectedDay);
    } else {
      setHorariosDoDia([]);
    }
  }
 
export function onDateSelect (date, setSelectDate) {
  setSelectDate (date);
} 

export function ZerarObjetoHorariosDoDia (horariosDoDia, setHorariosDoDia, horarioClicado) {
  horarioClicado = 0;
  
  let newHorarios = [...horariosDoDia];
  newHorarios.map((horario) => {
    horario.selecionado = "n";
  })
  setHorariosDoDia (newHorarios)
};

export function RetornaMsg (resp, horario) {
  let result = '';
  

  if (resp.length === 2 ) {
    result = 'Os dois horários que você selecionou foram pagos por outro aluno. Por favor, selecione outros horários.'
  } else {
    let a = horario.find ((h) => h.id === resp[0]);
    
    result = "O horário " + a.inicio + " - " + a.fim +  " foi pago por outro aluno. Por favor, selecione outro horário."
  }
  return result;
}