import { Grid, Typography, Box } from '@mui/material';

function PaymentResumo ({aulaMarcada}) {
    
    return <> {aulaMarcada !== undefined && aulaMarcada!==null ? <Box fullWidth sx={{ px: 3, maxWidth:'100%',  marginBottom: 0, 
                     borderRadius: '4px', border: 'solid', borderWidth: '1px', borderColor: 'rgba(0,0,0,0.12)', paddingY: '1em',
                    }} variant="outlined">
  
                            <h5 style={{ fontWeight: "600" }}>Resumo do pedido</h5>
                            <hr style={{ width: '100%', marginTop: '0.3em' }} />
                        
                            <Grid container spacing={1} sx={{ mt: 0 }} xs={12}>
                                    <Grid item xs={12} >
                                        <Typography variant="body2" component="p">
                                            <strong>Data: </strong>{aulaMarcada.current.data}
                                        </Typography>
                                    </Grid>
                                 
                                    <Grid item xs={12}>
                                        <Typography variant="body2" component="p">
                                           
                                            <strong>{aulaMarcada.current.inicioFim.length === 1 ? 'Horário: ' : 'Horários: '}</strong>
                      
                                            {aulaMarcada.current.inicioFim[0]}
                                            {aulaMarcada.current.inicioFim.length === 2 ? ' e ' + aulaMarcada.current.inicioFim[1]  : null}
                                        </Typography>
                                    </Grid>
                                
                                    <Grid item xs={12} >
                                        <Typography variant="body2" component="p">
                                            <strong>Valor: </strong>{"R$ " + aulaMarcada.current.valor}
                                        </Typography>
                                    </Grid>
                            </Grid>
            </Box> : null}
    </>;
  } export default PaymentResumo;