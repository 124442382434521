import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, Grid } from '@mui/material';

function ModalSucessoErro (props) {
       
    function Icone () {
        if (props.tipo === "success") {return <> <CheckCircleOutlineSharpIcon color="success" sx={{fontSize: "5em"}}></CheckCircleOutlineSharpIcon> </>}
        if (props.tipo === "error") {return <> <ErrorOutlineIcon color="error" sx={{fontSize: "5em"}}></ErrorOutlineIcon> </>}
        if (props.tipo === "warning") {return <> <WarningAmberSharpIcon color="warning" sx={{fontSize: "5em"}}></WarningAmberSharpIcon> </>}
        if (props.tipo === "info") {return <> <InfoOutlinedIcon color="info" sx={{fontSize: "5em"}}></InfoOutlinedIcon> </>}
    }
    
    return <>

    <Dialog open={props.open} aria-labelledby="form-dialog-title">
        
        <DialogContent>
            
            <DialogContentText>
                
                <Grid container display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
                    <Grid item xs={12} >
                        {Icone()}
                    </Grid>
                    
                    <Grid item xs={12} mt={3} >
                        {props.msg}
                    </Grid>
                    {props.componente && (<Grid item xs={12} mt={3} >
                        {props.componente}
                    </Grid>)}
                </Grid>
            
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClickBtn1} color={props.colorBtn1}>
                {props.btn1}
            </Button>
            {props.btn2 && (<Button onClick={props.onClickBtn2} color={props.colorBtn2}>
                {props.btn2}
            </Button>)}
        </DialogActions>
    </Dialog>

   </>

} 
ModalSucessoErro.defaultProps = {
    open: false,
    onClick: () => {},
    tipo: "success",
    msg: "",
    btn1: "Fechar"
    
}
export default ModalSucessoErro;

