import React from 'react';
import { Container, Typography, Grid, Avatar } from '@mui/material';
import TestimonialCard from './TestemunhoCard';
import man1 from '../../../data/images/estudante_homem.jpg';
import woman1 from '../../../data/images/estudante_mulher1.jpg';
import woman2 from '../../../data/images/estudante_mulher2.jpg';

const Testemunhos = () => {
  return (

   
    <Container maxWidth="lg" sx={{ py: 2 }}>

    <Grid container spacing={4} justifyContent="center" alignItems="center">
      
      <Grid item xs={12}>
        <Typography variant="h4" align="center" gutterBottom>
          O que os alunos estão dizendo
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Avatar alt="Maria" src={woman1} sx={{ width: 80, height: 80, margin: '0 auto' }} />
        <Typography variant="subtitle1" align="center" gutterBottom>
          "O BF é super didático e conseguiu me fazer entender a Física de uma forma muito clara." - Maria, estudante do 3º ano do Ensino Médio.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Avatar alt="João" src={man1} sx={{ width: 80, height: 80, margin: '0 auto' }} />
        <Typography variant="subtitle1" align="center" gutterBottom>
          "Com as aulas do BF, minha nota em Física melhorou bastante!" - João, estudante do 9º ano do Ensino Fundamental.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Avatar alt="Ana" src={woman2} sx={{ width: 80, height: 80, margin: '0 auto' }} />
        <Typography variant="subtitle1" align="center" gutterBottom>
          "Recomendo as aulas do BF para todos que querem aprender Física de forma eficiente." - Ana, estudante do Ensino Médio.
        </Typography>
      </Grid>
    </Grid>
    </Container>
  );
}

export default Testemunhos;
