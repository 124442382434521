import { useState } from 'react';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, Grid } from '@mui/material';

function ModalSucessoErro (props) {
    
    const { open, data, msg, onClickBtn2, success } = props;
    const [openModal, setOpenModal] = useState(open);

    function Icone () {
        if (data.successTipo === "success" &&  success) {return <> <CheckCircleOutlineSharpIcon color="success" sx={{fontSize: "5em"}}></CheckCircleOutlineSharpIcon> </>}
        if (data.errorTipo   === "error"   && !success) {return <> <ErrorOutlineIcon            color="error"   sx={{fontSize: "5em"}}></ErrorOutlineIcon>            </>}
        if (data.errorTipo   === "warning" && !success) {return <> <WarningAmberSharpIcon       color="warning" sx={{fontSize: "5em"}}></WarningAmberSharpIcon>       </>}
        if (data.errorTipo   === "info"    && !success) {return <> <InfoOutlinedIcon            color="info"    sx={{fontSize: "5em"}}></InfoOutlinedIcon>            </>}
    }
    
    function handleBtn1Click (e) {
        e.preventDefault();
        setOpenModal(false);
        props.onClose(e);
    }
    return <>
     
    {open && (<Dialog open={open} aria-labelledby="form-dialog-title">
        
        <DialogContent>
            
            <DialogContentText>
                
                <Grid container display="flex" flexDirection={"column"} alignItems="center" justifyContent="center">
                    <Grid item xs={12} >
                        {Icone()}
                    </Grid>
                    
                    <Grid item xs={12} mt={3} >
                        {msg}
                    </Grid>
                    {data.componente && (<Grid item xs={12} mt={3} >
                        {data.componente}
                    </Grid>)}
                </Grid>
            
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={(e) => handleBtn1Click(e)} color={data.btn1.color}>
                {data.btn1.label}
            </Button>
            {data.btn2.mostrar ? (<Button onClick={(e) => onClickBtn2 (e)} color={data.btn2.color}>
                {data.btn2.label}
            </Button>) : null}
        </DialogActions>
    </Dialog>
    )}
   </>

} 

export default ModalSucessoErro;

