function RetornarTotalHorariosSelecionados(horariosDoDia) {
  let totalHorariosSelecionados = 0;
  
  if (typeof horariosDoDia !== 'undefined' && horariosDoDia !== null) {horariosDoDia.forEach((horario) => {
    if (horario.selecionado === "s") { totalHorariosSelecionados++; };
  })}
  
  return totalHorariosSelecionados;
} export default RetornarTotalHorariosSelecionados;

export function ZerarObjetoHorariosDoDia (horariosDoDia, setHorariosDoDia) {
  let newHorarios = [...horariosDoDia];
  newHorarios.map((horario) => {
    horario.selecionado = "n";
  })
  setHorariosDoDia (newHorarios)
};

export function valorTotal (horariosDoDia) {
  let valorTotal = 0;
  let totalHorariosSelecionados = 0;
  
  horariosDoDia.map((horario) => {
    if (horario.selecionado === "s") { valorTotal = valorTotal + horario.valor};
    if (horario.selecionado === "s") { totalHorariosSelecionados++; };
  });
  
  if (totalHorariosSelecionados === 2) { valorTotal = valorTotal - (valorTotal * horariosDoDia[0].desconto / 100); };

  return valorTotal;
} 

