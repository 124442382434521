import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Typography, Grid, Container, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import BF from '../../../data/images/BF_tronco.png'; 

function Heading() {
    const defaultTheme = createTheme();
    const navigate = useNavigate();

    const theme = createTheme({
        palette: {
        background: {default:  '#ffffff'
        }
        },
        components: {
            MuiGrid: {
                  styleOverrides: {
        root: {
          marginTop: defaultTheme.spacing(3),
          marginBottom: defaultTheme.spacing(3),
        },
        image: {
          
          
          marginBottom: defaultTheme.spacing(2),
        },
      }}}});
  
      const classes = theme;
     
      function btnClick(e) {
        e.preventDefault();
        navigate('/login');
      }

  return (
    <>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Container backgroundColor= {theme.palette.primary.main}>
    <Grid container  spacing={4} backgroundColor={theme.palette.primary.main} style={{height: '300px'}} >
      
      <Grid item xs={12} sm={6}>
        <Typography variant="h4" gutterBottom color='#ffffff'>
          Aprenda Física, sem sair de casa!
        </Typography>
        
        <Typography variant="body1" gutterBottom color='#ffffff' style={{marginTop: '3em'}} >
          Está tendo dificuldades em Física? Quer se preparar para o vestibular ou o Enem? Agende uma aula particular online e tenha aula com o prof Bruno Fernandes (BF) de qualquer lugar do Brasil! 
        </Typography>
        
        <Button variant="contained" color="secondary" style={{marginTop: '1em'}} onClick={(e) => btnClick(e)}>
            Crie a sua conta gratuitamente
          </Button>
      </Grid>
      
      <Grid item xs={12} sm={6} >
        <img src={BF} alt="Professor Bruno Fernandes" style={{
           maxWidth: '150%',
           height: '140%',
           marginTop: '-4em',
           marginLeft: '8em',            
        }} />
      </Grid>
      
    </Grid>
    </Container>
    </ThemeProvider>
    </>
  );
}

export default Heading;