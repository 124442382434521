import React from 'react';
import { Container, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Mic, DesktopWindows, Security } from '@mui/icons-material';
import BF from '../../../data/images/studio.jpg';

const EspecificacoesTecnicas = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 1, mt: 2 }}>
      <Grid container spacing={4} alignItems="center">
        
      <Grid item xs={12} md={6} >
      <img src={BF} alt="Equipamentos" height="300" width="400" sx={{ borderRadius: 2, mt: 2 }} />
    </Grid>
        
        
        <Grid item xs={12} md={6}>
      <Typography variant="h4"   mb={2} mt={1}>
        Especificações Técnicas
      </Typography>
      <List sx={{ mb: 4 }}>
        <ListItem>
          <ListItemIcon>
            <Mic fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Audio com qualidade de estúdio." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <DesktopWindows fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Mesa digital de última geração para escrever na tela." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Security fontSize="large"/>
          </ListItemIcon>
          <ListItemText primary="A aula é realizada por meio do Google Meet, uma plataforma segura e fácil de usar." />
        </ListItem>
      </List>
    </Grid>

    



    </Grid>
    </Container>
  );
};

export default EspecificacoesTecnicas;