import React from 'react';
import ScheduleManagement from '../components/ScheduleManagement/ScheduleManagement';
import NavBar from '../components/NavBar/NavBar';

const ScheduleManagementPage = () => {
  return (
    <>
    <NavBar />
    <div className='container mb-4 mt-4'>
      
      <ScheduleManagement />
    </div>
    </>
  );
};

export default ScheduleManagementPage;
