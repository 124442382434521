import { localStorageUsuarioName } from '../../data/config/config';

function isEmail(email) {
    const expressaoRegularEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return expressaoRegularEmail.test(email);
  }

function validaEmail(email) {
  if (email !== '') {
    if (isEmail(email)) {
      return true
    } else {
      return false;
    }
  }
}

export function validarEmail (dado, setEmailValido) {

    if (validaEmail(dado) || dado === '') {
      setEmailValido(true);
    } else {
      setEmailValido(false);
    }}

export function AtualizaCampos (event, setDados) {
        event.preventDefault();
        const { name, value } = event.target;
        
          setDados((prev) => ({...prev, [name]: value}));
}

export function validarFormulario(value, dadosUsuario, emailValido, setBotaoLigado) {

    if (value.nome === dadosUsuario.nome && value.sobrenome === dadosUsuario.sobrenome
      && value.email === dadosUsuario.email && value.senha === dadosUsuario.senha) {

      setBotaoLigado(false);
    } else {
      if (emailValido) { setBotaoLigado(true); }
      else
        setBotaoLigado(false);
    }
  }

export function RetornaDadosUsuario () {
    const usuario = localStorage.getItem(localStorageUsuarioName);
    if (usuario) {
        return JSON.parse(usuario);
    }
    return null;
}

// Atualiza o objeto usuário do armazenamento local
export function AtualizaLocalStorageUsuario (usuario) {
    localStorage.setItem(localStorageUsuarioName, JSON.stringify(usuario));
}