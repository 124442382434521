import { useState, useContext } from 'react';

import { Alert, Button, Badge } from '@mui/material';

import { VariaveisContext } from '../../services/variaveisProvider';
import  RetornarTotalHorariosSelecionados, { valorTotal }  from './funcoes.js';

import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ModalSucessoErro from '../gerais/ModalSucessoErro/ModalSucessoErro';

function TimeSlots({ onClickBtnAssuntos}) {
  
  const { loading, horarioClicado, horariosDoDia, setHorariosDoDia  } = useContext(VariaveisContext);
  const [showModalErro3Horarios, setShowModalErro3Horarios] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
      
  
 function modifyTimeSlotSelect(e, timeSlot) {
    e.preventDefault();
    
    if (timeSlot === '') {return;}
    // Encontrar o índice do timeSlot que você deseja modificar
    const newHorarios = [...horariosDoDia];
    const index = newHorarios.findIndex((slot) => slot === timeSlot);

    if (newHorarios[index].selecionado === "s") {
      newHorarios[index].selecionado = "n";

      if (RetornarTotalHorariosSelecionados(horariosDoDia) === 1) {
        setOpenSnack(true);
      }
    } else {
      if (RetornarTotalHorariosSelecionados(horariosDoDia) === 2) {
        setShowModalErro3Horarios(true);
      } else {
        newHorarios[index].selecionado = "s";
        setOpenSnack(true);
      }
    }
    
    horarioClicado.current = timeSlot.id;
    setHorariosDoDia(newHorarios);
    
  }

  return <>
      <h5 className="mb-1">Horários disponíveis</h5>
      <hr></hr>
            
      {!loading.isSuccess && loading.fim ?
        <Alert severity="error">Não foi possível carregar os horários disponíveis. Tente novamente mais tarde.</Alert>
        : null}
      
      {horariosDoDia.length === 0 && loading.isSuccess ? 
        <Alert icon={<SentimentVeryDissatisfiedIcon fontSize="inherit" />} severity="warning" >Infelizmente, não há horários disponíveis nesta data</Alert>
        : null }
      
             <div className="row mx-auto" > 
             <div style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '1em',
              marginTop: '1.0em',
          }}>
              {RetornarTotalHorariosSelecionados(horariosDoDia) > 0 && loading.isSuccess ? (
                <div className='col-6 col-lg-6'>
                  <Alert icon={<PriceCheckIcon fontSize="inherit" />} severity="success"><strong>{"Total: "}</strong>{"R$ " + valorTotal(horariosDoDia)}</Alert>
                </div>
                ) : null}
      
          
               {RetornarTotalHorariosSelecionados(horariosDoDia) === 1 && loading.isSuccess ? (
                <>
                  <div className='col-6 col-lg-6'>   
                    <Alert severity="warning">{"Selecione mais um horário e ganhe " + horariosDoDia[0].desconto + "% de desconto!"}</Alert>
                    </div>            
                </>
                ) : null}
          
              
          
              {RetornarTotalHorariosSelecionados(horariosDoDia) === 2 && loading.isSuccess ? (
                <>      
               <div className='col-6 col-lg-6'>
                   <Alert icon={<SentimentSatisfiedAltIcon fontSize="inherit" />} severity="success">Você ganhou {horariosDoDia[0].desconto}% de desconto por ter escolhido dois horários!</Alert>
                   </div>            
                </>
              ) : null}
              
        </div>
        </div>
       
      {horariosDoDia.length > 0 && loading.isSuccess ? <>
       <div className="flex mt-3" style={{gap:'2em'}} >
          <div style={{
              
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '4em',
              marginTop: '1.5em',
              
          }}>
          
          {horariosDoDia.map((horario, index) => (
            
              <Badge style={{marginTop:'2em', marginLeft:'2em', marginRight:'2em'}} badgeContent={"R$ " + horario.valor} color="error"   >
              <button 
              key={index}
                className={`btn ${horario.selecionado === 's'
                    ? 'btn-success'
                    : 'btn-outline-success'} `}

                onClick={(e) => modifyTimeSlotSelect(e, horario)}
              >
               {horario.inicio} - {horario.fim}
               
              </button>
              </Badge>
           
          ))}
          </div>
          <div className="text-center mt-4">
            <Button disableElevation variant="contained" onClick={(e) => onClickBtnAssuntos(e)}
              disabled={RetornarTotalHorariosSelecionados(horariosDoDia) === 0}
            >Escolher assuntos</Button>

          </div>
          
          
          <ModalSucessoErro open={showModalErro3Horarios} tipo="warning" msg="Você só pode escolher no máximo 2 horários por vez!"
                            btn1="Fechar" onClickBtn1={(e) => setShowModalErro3Horarios (false)}
          /> 

          
        </div>
        </> : null}
    </>
} export default TimeSlots;
