import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, MenuItem, Select, TextField, InputLabel, Box, } from '@mui/material';
import { getCardBrand, validarCodigoSeguranca, validarCartao } from './PaymentFuncoes';
import Loading from '../gerais/Loading/Loading';
import { VariaveisContext } from "../../services/variaveisProvider"
import ApiCall from "../../services/api"
import { RetornaObjetoPagamentoCartao, AutorizadoPagSeguro } from './PaymentFuncoes'; 
import ModalSucessoErro from '../gerais/ModalSucessoErro/ModalSucessoErro';
import MercadoPagoPagamento from '../MercadoPago/Pagamento';

function Payment({ onBackPayment, aulaMarcada, handleHorarioNaoDisponivel  }) {
  const meses = [...Array(12).keys()].map((mes) => `${mes < 9 ? '0' : ''}${mes + 1}`);
  const anos = [...Array(18).keys()].map((ano) => 2023 + ano);

  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [isValidCard, setIsValidCard] = useState(true);
  const [isValidCVV, setIsValidCVV] = useState(true);
  const [iniciandoPayment, setIniciandoPayment] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentMsg, setPaymentMsg] = useState('');
  const sucessoEtapas = useRef ({ iniciando: false, finalizado: false, horarioDisponivel: false, pagamentoEfetuado: false, idPedido: 0});
  const [card, setCard] = useState({number: "", expiryMonth: "", expiryYear: "", securityCode: "", name: "" });
  const {loading, setLoading, usuario, horarioClicado} = useContext (VariaveisContext);
  const [loadingMercadoPago, setLoadingMercadoPago] = useState (true);
  const navigate = useNavigate();
  const responsePagSeguro = useRef (null);

    
  async function onClickPayment (card) {
    // verificar se servidor está on e determinar se aquele horario ainda está disponivel
    setIniciandoPayment (true);
    sucessoEtapas.current.iniciando = true;
    sucessoEtapas.current.finalizado = false;
    sucessoEtapas.current.horarioDisponivel = false;
    sucessoEtapas.current.pagamentoEfetuado = false;
    sucessoEtapas.current.erro = '';
    
    setLoading ({ ...loading, msg: 'Conectando aos nossos servidores...' });
    
    // ETAPA 1 - verificar se o horario ainda está disponivel
    let a = {idHorarios: horarioClicado.current, idUsuario: usuario.current.id};
         
         const result = await ApiCall('conferirHorarioDisponivel', a, Loading); // chama a API de login
         setLoading (result.newLoading);

         if (result.newLoading.isSuccess) {
            if (handleHorarioNaoDisponivel (result)) {
              sucessoEtapas.current.horarioDisponivel = true;
            } else {
              sucessoEtapas.current.horarioDisponivel = false;
              
            }
          } else {
              sucessoEtapas.current.horarioDisponivel = false;
              sucessoEtapas.current.erro = 'horario';
         }
    
    // ETAPA 2 - fazer o pagamento no PagSeguro
    if (sucessoEtapas.current.horarioDisponivel && sucessoEtapas.current.erro === '') {
    setLoading ({ ...loading, msg: 'Iniciando pagamento...' });
    
    let m = RetornaObjetoPagamentoCartao (Number (aulaMarcada.current.valor + '00') , 'AP' + sucessoEtapas.current.idPedido, 1, card)
    let n = aulaMarcada.current;
    let d = {...m, ...n};
    
    const result3 = await ApiCall ('aulasPagarComCartao', d, loading);
    setLoading (result3.newLoading);

    if (result3.newLoading.isSuccess) {
      // primeiro verifica o que o PagSeguro vai responder, se foi autorizado ou não o pagamento
      responsePagSeguro.current = result3.newLoading.response.data;
      let c = AutorizadoPagSeguro (result3.newLoading.response.data); 
      if (c.autorizado)
       {
        sucessoEtapas.current.pagamentoEfetuado = true;
         
       } else {
        setPaymentMsg (c.msg);
        sucessoEtapas.current.pagamentoEfetuado = false;
        sucessoEtapas.current.erro = 'nao-autorizado';
       }
      } else {
        sucessoEtapas.current.pagamentoEfetuado = false;
        sucessoEtapas.current.erro = 'pagamento';
        setPaymentMsg (result3.newLoading.modalMsg);
        
      }
    }
    
    // fechar msg para o usario
    if ((sucessoEtapas.current.iniciando === true && sucessoEtapas.current.finalizado === false) && (!sucessoEtapas.current.horarioDisponivel)
        && (sucessoEtapas.current.erro === 'horario' || sucessoEtapas.current.erro === 'pagamento')
    ) {
      setPaymentMsg ('Ocorreu um erro na comunicação com os nossos servidores para o registro do pedido. Tente novamente mais tarde.');
      setPaymentError (true);
    }

    sucessoEtapas.current.finalizado = true;
    sucessoEtapas.current.iniciando = false;

    if (sucessoEtapas.current.pagamentoEfetuado && sucessoEtapas.current.erro === '') {
        setPaymentMsg ('Pagamento efetuado com sucesso! O link para a aula está disponível na página de Gerenciamento das Aulas. Caso não encontre, envie uma mensagem para o professor.');
        setPaymentSuccess (true);
    }
    
    if (sucessoEtapas.current.horarioDisponivel && !sucessoEtapas.current.pagamentoEfetuado && sucessoEtapas.current.erro === 'pagamento') {
      
      setPaymentError (true);
  }

  if (sucessoEtapas.current.horarioDisponivel && !sucessoEtapas.current.pagamentoEfetuado && sucessoEtapas.current.erro === 'nao-autorizado') {
      
    setPaymentError (true);
}
  
  setIniciandoPayment (false);  
  setLoading ({ ...loading, fim: true });
  }
  
 
  const handlePaymentSuccess = (e) => {
    e.preventDefault();
    navigate ('/gerenciamento');
  };

   

  useEffect(() => {
    const { number, name, expiryMonth, expiryYear, securityCode } = card;
    
    if (card.number !== '') { if (validarCartao(card.number)) { setIsValidCard(true); } else { setIsValidCard(false); } } else { setIsValidCard(true); }

    if (card.securityCode !== '') { if (validarCodigoSeguranca(card.securityCode, getCardBrand(card.number))) { setIsValidCVV(true); } else { setIsValidCVV(false); } } else { setIsValidCVV(true); }

    if ((number && name && expiryMonth && expiryYear !== '' && isValidCVV && isValidCard) && (securityCode.length === 3)) { setPaymentEnabled(true); } else
      setPaymentEnabled(false);
  }, [card]);
  
  function handleFinishPagamento (response) {
    
    if (response !== null && response !== undefined) {
    // PAGAMENTO BEM SUCEDIDO
       if (response.isSuccess) {
        setPaymentMsg ('Pagamento efetuado com sucesso! O link para a aula está disponível na página de Gerenciamento das Aulas. Caso esteja indisponível, envie uma mensagem para o professor.')
        setPaymentSuccess (true);
       }
    // PAGAMENTO NÃO AUTORIZADO
       else {
        setPaymentMsg (response.modalMsg);
        setPaymentError (true);

       }
  } else {
    setPaymentMsg ('Ocorreu um erro na comunicação com os nossos servidores para o registro do pedido. Tente novamente mais tarde.');
    setPaymentError (true);
  }
}

  return (
    <>
      <MercadoPagoPagamento dados = {aulaMarcada.current} email={usuario.current.email} onFinish={(response) => handleFinishPagamento (response)}/>
      
      {/*}
      <Box sx={{
         px: 3,  
        borderRadius: '4px', border: 'solid', borderWidth: '1px', borderColor: 'rgba(0,0,0,0.12)', paddingY: '1em',
      }} variant="outlined">

        <h5 className="mb-1" style={{ fontWeight: "600" }}>Informações do cartão</h5>
        <hr style={{ width: '100%', marginTop: '0.3em' }} />
        
        <Grid container spacing={2} sx={{ mt: 0 }} xs={12}>
          <Grid item xs={12}>
            <TextField fullWidth label="Número do cartão" variant="outlined" onChange={(event) => setCard((prevState) => ({ ...prevState, number: event.target.value }))}

              error={!isValidCard}
              helperText={!isValidCard ? 'Número de cartão inválido' : ''} />
          </Grid>

          <Grid item xs={12} md={4}>
            <InputLabel sx={{ mb: 1 }} id="meses-label">Mês de vencimento</InputLabel>
            <Select
              labelId="meses-label"
              fullWidth variant="outlined"
              onChange={(event) => setCard((prevState) => ({ ...prevState, expiryMonth: event.target.value }))}
            >
              {meses.map((mes) => (
                <MenuItem key={mes} value={mes}>
                  {mes}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel sx={{ mb: 1 }} id="anos-label">Ano de vencimento</InputLabel>
            <Select fullWidth
              labelId="anos-label"
              variant="outlined"
              onChange={(event) => setCard((prevState) => ({ ...prevState, expiryYear: event.target.value }))}
            >
              {anos.map((ano) => (
                <MenuItem key={ano} value={ano}>
                  {ano}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel sx={{ mb: 1 }} id="cvv-label">Código de Segurança</InputLabel>
            <TextField labelId="cvv-label" fullWidth label="CVV" variant="outlined" onChange={(event) => setCard((prevState) => ({ ...prevState, securityCode: event.target.value }))}
              error={!isValidCVV}
              helperText={!isValidCVV ? 'Código de segurança inválido' : ''} />
          </Grid>


          <Grid item xs={12}>
            <TextField fullWidth label="Nome no cartão" variant="outlined" onChange={(event) => setCard((prevState) => ({ ...prevState, name: event.target.value.toUpperCase() }))}
              value={card.name} />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-around" sx={{ mb: 1, mt: 3 }}>
          <Grid item>

            <Button variant="outlined" onClick={(e) => {onBackPayment()}}>Voltar</Button>
          </Grid>
          <Grid item>
            <Button style={{ width: "200px" }} disabled={!paymentEnabled} variant="contained" disableElevation onClick={() => onClickPayment(card)}>Pagar</Button>
          </Grid>

        </Grid>
        
      </Box>
              */}


      {/* Dialog de processamento do pagamento */}
      <Loading open={iniciandoPayment} msg={loading.msg} />


      {/* Alerta de erro em caso de erro no pagamento */}
      {paymentError ? 
        <ModalSucessoErro open={paymentError} msg={paymentMsg} tipo={"error"} btn1={'Fechar'} onClickBtn1={(e) => {e.preventDefault(); setPaymentError(false)}} onClose={() => setPaymentError(false)} />
      : null}

      {/* Alerta de sucesso em caso de pagamento realizado com sucesso */}
      {paymentSuccess ? (
        <ModalSucessoErro open={paymentSuccess} msg={paymentMsg} tipo={"success"} btn1={'OK'} onClickBtn1={(e) => handlePaymentSuccess (e)} onClose={() => setPaymentSuccess(false)} />
      ) : null}
    </>

  );




}


export default Payment;


