import axios from 'axios';
import { localStorageUsuarioName, endpoint, URLServidorPrincipal } from '../data/config/config';

function RetornaEndpointURL (endpointAlias) {
    return endpoint[endpointAlias].url;
}


function ProcessarErro(err) {
    let msg = 'Erro desconhecido.'; let statusCode = 0;
    if (err.hasOwnProperty("response"))
       {
        statusCode = err.response.status;
        
        if (err.response.data.msg !== undefined && err.response.data.msg !== null) 
         {
            msg = err.response.data.msg
            if (err.response.status === 403) {
            msg = "Acesso não autorizado. Faça login novamente."
            document.location = "/login";
        }
    
        if (err.response.status === 500) {msg = 'Erro interno do servidor. Tente novamente mais tarde.'}
        
        statusCode = err.response.status;
    }                 
} else {
    msg = "O servidor encontra-se fora do ar. Tente novamente mais tarde.";
    statusCode = 503;
}
        
  return { statusCode, msg };
}

async function ApiCall(apiInfo) {
    
    const { endpoint, data, type } = apiInfo;

    const sessionToken = localStorage.getItem(localStorageUsuarioName) ? JSON.parse (localStorage.getItem(localStorageUsuarioName)).token : null;

    const api = axios.create({
        baseURL: URLServidorPrincipal(),
        headers: { Authorization: `Bearer ${sessionToken}` },
    });
    
        let result = { };
        
         
        try {
            let a = {};
            if (type === 'get ') {const response = await api.get  (RetornaEndpointURL (endpoint), data); a = response};
            if (type === 'post') {const response = await api.post (RetornaEndpointURL (endpoint), data); a = response};
            if (type === 'put')  {const response = await api.put  (RetornaEndpointURL (endpoint), data); a = response};
            console.log (a);            
            result.statusCode = a.status;
            result.response = a.data;
            result.isSuccess = true;
            result.modalMsg = a.data.msg;
            result.fim = true;
            result.conectou = true;

        } catch (err) {
            console.log (err);            
            let error = ProcessarErro (err);
            
            result.statusCode = error.statusCode;
            result.error = err;
            result.isSuccess = false;
            result.modalMsg = error.msg;
            result.fim = true;
            result.conectou = false;
            
        } finally {
            result.inicio = false;
            result.fim = true;
            
            return result;
        }
    };
 
export default ApiCall;





