import { useEffect, useState } from 'react';
import ApiCall from '../../../services/api2';
import { Dialog, DialogContent, Box, CircularProgress } from '@mui/material';
import ModalSucessoErro from '../ModalSucessoErro/ModalSucessoErro2';

function Loading2 (props) {

const {open, tipo, msg, onClose, api, modal} = props;
const [openModal, setOpenModal] = useState (false); // se true, mostra o modal de sucesso ou erro

const [loading, setLoading] = useState ({
    inicio: false, 
    fim: false, 
    conectou: false, 
    isSuccess: false, 
    modalMsg: '', 
    response: null, 
    error: null });


function chamarApi () {
    
    const getResult = async () => {
        const result = await ApiCall (api, setLoading);
        setLoading(result);
        if (modal.mostrar) {
            setOpenModal(true);
        } else {
            onClose (result);
        }
    };
    
    getResult(); 
}

function handleFecharLoading () {
    onClose (loading);
}

function onClickBtn1 (e) {
    e.preventDefault();
    handleFecharLoading ()
  }

 
return <>
    
    {open ? <> 
        {tipo === 'normal' && loading.fim === false ? <Dialog open={open}>
        <DialogContent>
            <Box display="flex" alignItems="center">
                    <CircularProgress size={40} />
                    {msg === undefined ? null : <Box ml={3} sx={{fontSize: '1.1em'}}>{msg}</Box>}
           </Box>
        </DialogContent>
    </Dialog>
    : null
    }

    {tipo === 'simples' && loading.fim === false ? <Box sx = {{ height: "100%", display:"flex", alignItems:"center", justifyContent:"center", minHeight: '100vh',}}>
                      <CircularProgress size={40} />
                    {msg === undefined ? null : <Box ml={3} sx={{fontSize: '1.1em'}}>{msg}</Box>}
              </Box>
   : null  
   }

    {tipo === 'oculto' && loading.fim === false} 
    
    {!loading.fim ? chamarApi() : null}

    {/* MOSTRAR MENSAGENS DE ERRO OU SUCESSO, SE APLICAVEIS */}
    {modal.tipo === 'modal' && modal.mostrar ? 
        <ModalSucessoErro
            open={openModal}
            data={modal} 
            msg={loading.modalMsg}
            onClose={(e) => onClickBtn1(e)}
            success={loading.isSuccess} 
            /> : null} 
    </> : null}         
</>
}
export default Loading2;

// props:
// open: boolean // se true, mostra o loading
// msg: string // mensagem que aparece abaixo do loading
// tipo: string // tipo de loading: 'normal (possui background e não é possível sair' 'simples' sem backgorund para ser usado em boxes ou 'nenhum' (para quando precisamos faze apenas um chamado na api
// onClose: function // função que é chamada quando o loading é fechado.
